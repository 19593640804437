import React, {  useEffect, useRef, useState } from 'react';
import {
    Textarea, Accordion, AccordionItem, AccordionButton,
    Box, AccordionIcon, AccordionPanel, useToast, Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { StripeCheckout } from '../../components/Payments/StripeCheckout';

export const Checkout = ({prevSteps, valuesVehicle, total, totalExtra, passengers, valuesSummary, valuesContact}) => {

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    const ref = useRef(null);
    const [code, setCode] = useState('');
    const [newTotal,setNewTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const toast = useToast();
    const toastIdRef = useRef();
    const history = useNavigate();
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [Error, setError] = useState(false);
    const [orderID, setOrderID] = useState(false);

    const handleApplyCode = () => {

        if(code.toUpperCase() === 'ROYAL10'){
            let newPrice = 0;
            let discount = 0;
            if(total > 130){
                newPrice = (total * (100 - 10))/ 100;
                setNewTotal(newPrice);
                discount = total - newPrice;
                setDiscount(discount);
                toastIdRef.current = toast(
                    {
                        status: "success",
                        title: 'Success',
                        description: 'Coupon code applied successfully',
                        position: 'top',
                        duration: 2000,
                        isClosable: true,
                    }
                );
            }else{
                toastIdRef.current = toast(
                    {
                        status: "error",
                        title: 'Error',
                        description: 'Minimum amount of 130 USD to apply the code.',
                        position: 'top',
                        duration: 3000,
                        isClosable: true,
                    }
                );
            }
        }else{
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Not valid code.',
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                }
            );
        }
    }



    const handleSubmit = e => {

        setShow(true);

        let data = {};
        let clientDetail = {};
        let services = {};
        let extras = valuesVehicle.extras;

        clientDetail.firstName = valuesContact.firstName;
        clientDetail.lastName = valuesContact.lastName;
        clientDetail.email = valuesContact.email;
        clientDetail.phone = valuesContact.phone;

        services.type = valuesSummary.tranferType;
        services.name = valuesVehicle.vehicle.nombreVehiculo;
        services.idVehicle = valuesVehicle.vehicle.idVehiculo;
        services.quantity = 1;
        services.price = valuesVehicle.vehicle.precioFinal;
        services.discount = discount;
        services.total = newTotal > 0 ? newTotal : total;
        services.subtotal = total;
        services.payment = valuesContact.paymentMethod;
        services.passenger = passengers;
        services.noAdults = valuesSummary.noAdults;
        services.noChilds = valuesSummary.noChildrens;

        data.extra = extras.length > 0 ? true : false;
        data.extras = valuesVehicle.extras;
        data.clientDetail = [clientDetail];
        data.services = [services];

        data.pickupLocation = valuesSummary.pickupLocation;
        data.pickOffLocation = valuesSummary.pickoffLocation;
        data.datePickup = valuesSummary.pickupDate;
        data.timePickup = valuesSummary.pickupTime;
        data.dateReturnPickup = valuesSummary.pickupDateReturn;
        data.timeReturnPickup = valuesSummary.pickupTimeReturn;

        data.pickupDateTime = valuesSummary.pickupDate + ' - ' + valuesSummary.pickupTime;
        data.returnDateTime = valuesSummary.pickupDateReturn + ' - ' + valuesSummary.pickupTimeReturn;
        data.flightNumber = valuesContact.flightNumber;
        data.airline = valuesContact.airline;
        data.ordernotes = valuesContact.notes;
        data.comments = valuesContact.comments;
        data.datecreated = formatDate(new Date());
        data.estado = 1;

        data.cc = '';
        data.emailSend = valuesContact.email;

        fetch('http://localhost:5000/api/order/savereservation', {
            method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then((response) => {
            const res = response.json();
            //handleSendEmail(data);
            //history("/order-confirmed");
        })
        .then((data) => {
        })
        .catch((error) => {
            setShow(false);
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Order Failed, try again.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        });
    }

    const handleSendEmail = (data) => {
        data.emailSend = 'ricardo@bemoresocial.com.mx';
        data.cc = 'andry.matoscaamal@gmail.com';
        fetch('https://api.royalpickup.com/api/mail/sendmail', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response) => {
        })
        .then((json) => {
        })
        .catch((err) => {
        });
    }

    const handleCheckout = async () => {

        let cartItems = [];

        if(valuesVehicle?.extras?.length > 0){

            var items = valuesVehicle.extras;

            cartItems = items.reduce(function(obj, item){
                obj["name"] = item.nombre;
                obj["image"] = 'https://www.shutterstock.com/image-vector/red-white-black-extra-grunge-260nw-1637401540.jpg';
                obj["desc"] = item.descripcion;
                obj["id"] = item.id;
                obj["price"] = item.precio;
                obj["cartQuantity"] = item.cantidad;

                cartItems.push(obj);
                return obj;
            },{})
        }

        var newCart = {
            "name": valuesSummary.tranferType,
            "image": valuesVehicle.vehicle.idVehiculo === 1 ? 'https://royalpickupsrc.s3.amazonaws.com/van.webp':'https://royalpickupsrc.s3.amazonaws.com/suburban.webp',
            "desc":valuesVehicle.vehicle.nombreVehiculo,
            "price": newTotal > 0 ? newTotal : total,
        };


        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "cartItems": [
                        cartItems
                    ]
                }
            )
        }


        /*try {
            const fetchResponse = await fetch('http://localhost:5000/api/stripe/create-checkout-session', settings);
            const data = await fetchResponse.json();
            console.log("data =>>>>>>>>>>>>", data);
            if(data.url){
                window.location.href = data.url;
            }
            return data;
        } catch (e) {
            return e;
        }

        */

    }

    // creates a paypal order
    const createOrder = (data, actions) => {

        const totalPaypal = newTotal > 0 ? newTotal : total;
        const descripcion = valuesSummary.tranferType + " - " + valuesVehicle.vehicle.nombreVehiculo;

        return actions.order.create({
            purchase_units: [
                {
                    description: descripcion,
                    amount: {
                        currency_code: "USD",
                        value: totalPaypal,
                    },
                },
            ],
        }).then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };

    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            //Guardado o actualizado de la orden
            setSuccess(true);
        });
    };

    //capture likely error
    const onError = (data, actions) => {
        setError(true);
    };

    useEffect(() => {
        if (success) {
            setShow(true);

            let data = {};
            let clientDetail = {};
            let services = {};
            let extras = valuesVehicle.extras;

            let paypalID = valuesContact.paymentMethod === 'Paypal' ? valuesContact.paymentMethod + " ID: " + orderID : valuesContact.paymentMethod;

            clientDetail.firstName = valuesContact.firstName;
            clientDetail.lastName = valuesContact.lastName;
            clientDetail.email = valuesContact.email;
            clientDetail.phone = valuesContact.phone;

            services.type = valuesSummary.tranferType;
            services.name = valuesVehicle.vehicle.nombreVehiculo;
            services.quantity = 1;
            services.price = valuesVehicle.vehicle.precioFinal;
            services.discount = discount;
            services.total = newTotal > 0 ? newTotal : total;
            services.subtotal = total;
            services.payment = paypalID;
            services.passenger = passengers;
            services.noAdults = valuesSummary.noAdults;
            services.noChilds = valuesSummary.noChildrens;

            data.extra = extras.length > 0 ? true : false;
            data.extras = valuesVehicle.extras;
            data.clientDetail = [clientDetail];
            data.services = [services];

            data.pickupLocation = valuesSummary.pickupLocation;
            data.pickOffLocation = valuesSummary.pickoffLocation;
            data.pickupDateTime = valuesSummary.pickupDate + ' - ' + valuesSummary.pickupTime;
            data.returnDateTime = valuesSummary.pickupDateReturn + ' - ' + valuesSummary.pickupTimeReturn
            data.flightNumber = valuesContact.flightNumber;
            data.airline = valuesContact.airline;
            data.ordernotes = valuesContact.notes;
            data.comments = valuesContact.comments;
            data.datecreated = formatDate(new Date());

            data.cc = '';
            data.emailSend = valuesContact.email;

            fetch('https://api.royalpickup.com/api/mail/sendmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response) => {
                response.json();
                handleSendEmail(data);
                history("/order-confirmed");
            })
            .then((data) => {
            })
            .catch((error) => {
                setShow(false);
                toastIdRef.current = toast(
                    {
                        status: "error",
                        title: 'Error',
                        description: 'Order Failed, try again.',
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                    }
                );
            });
        }
    },[success]);

    useEffect(() => {
        window.scroll({
            top: 5,
            behavior: "smooth"
        });
    // eslint-disable-next-line
    },[]);

    return (
        <div className="App">
            <div ref={ref} id="stepper1" className="bs-stepper desktop-version">
                <div className="bs-stepper-header">
                    <div className="step" data-target="#test-l-1">
                        <button className="step-trigger" onClick={() => prevSteps(1)}>
                            <span className="bs-stepper-circle active-step">1</span>
                            <span className="bs-stepper-label">Enter Ride Details</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-2">
                        <button className="step-trigger" onClick={() => prevSteps(2)}>
                            <span className="bs-stepper-circle active-step">2</span>
                            <span className="bs-stepper-label">Choose a Vehicle</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-3">
                        <button className="step-trigger" onClick={() => prevSteps(3)}>
                            <span className="bs-stepper-circle active-step">3</span>
                            <span className="bs-stepper-label">Enter Contact Details</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-3">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle active-step">4</span>
                            <span className="bs-stepper-label">Checkout</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                {
                    show ? <div className="loading"></div> : <></>
                }
                <div className='row'>
                    <div className='col-md-4 ml-2 mr-2'>
                        <div className='chbs-summary'>
                            <div className='summary-header'>
                                <h4>Contact & Billing Info</h4>
                            </div>
                            <div className='summary-fields'>
                                <div className='clear-fix'>
                                    <div className='field-left'>
                                        <div className='summary-field-name'>
                                        FirstName
                                        </div>
                                        <div className='summary-field-value'>
                                            {valuesContact.firstName}
                                        </div>
                                    </div>
                                    <div className='field-right'>
                                        <div className='summary-field-name'>
                                        Last Name
                                        </div>
                                        <div className='summary-field-value'>
                                            {valuesContact.lastName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='summary-fields'>
                                <div className='summary-field-name'>
                                E-MAIL ADDRESS
                                </div>
                                <div className='summary-field-value'>
                                {valuesContact.email}
                                </div>
                            </div>
                            <div className='summary-fields'>
                                <div className='summary-field-name'>
                                    Phone Number
                                </div>
                                <div className='summary-field-value'>
                                    {valuesContact.phone}
                                </div>
                            </div>
                        </div>

                        <div className='chbs-summary'>
                            <div className='summary-header'>
                                <h4>Payment Method</h4>
                            </div>
                            <div className='summary-fields'>
                                <div className='summary-field-name'>
                                    Your Choice
                                </div>
                                <div className='summary-field-value'>
                                    {
                                        valuesContact.paymentMethod
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-4 ml-2 mr-2'>
                        <div className='chbs-summary'>
                            <div className='summary-header'>
                                <h4>Ride Details</h4>
                            </div>
                            <div className='summary-fields'>
                                <div className='summary-field-name'>
                                Service Type
                                </div>
                                <div className='summary-field-value'>
                                Distance
                                </div>
                            </div>
                            <div className='summary-fields'>
                                <div className='summary-field-name'>
                                Transfer Type
                                </div>
                                <div className='summary-field-value'>
                                    {valuesSummary.tranferType}
                                </div>
                            </div>
                            <div className='summary-fields'>
                                <div className='summary-field-name'>
                                    Pickup Location
                                </div>
                                <div className='summary-field-value'>
                                    {valuesSummary.pickupLocation}
                                </div>
                            </div>
                            <div className='summary-fields'>
                                <div className='summary-field-name'>
                                    Drop Off Location
                                </div>
                                <div className='summary-field-value'>
                                    {valuesSummary.pickoffLocation}
                                </div>
                            </div>
                            <div className='summary-fields'>
                                <div className='summary-field-name'>
                                    PICKUP DATE, TIME
                                </div>
                                <div className='summary-field-value'>
                                    {valuesSummary.pickupDate}, {valuesSummary.pickupTime}
                                </div>
                            </div>
                            <div className='summary-fields'>
                                <div className='clear-fix'>
                                    <div className='field-left'>
                                        <div className='summary-field-name'>
                                        Total Distance
                                        </div>
                                        <div className='summary-field-value'>
                                            {valuesSummary.totalDistance}
                                        </div>
                                    </div>
                                    <div className='field-right'>
                                        <div className='summary-field-name'>
                                        Total Time
                                        </div>
                                        <div className='summary-field-value'>
                                            {valuesSummary.totalTime}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            valuesSummary.tranferType === 'Round Trip' ?
                            <>
                            <div className='chbs-summary'>
                                <div className='summary-header'>
                                    <h4>Return Ride</h4>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                        RETURN PICKUP LOCATION
                                    </div>
                                    <div className='summary-field-value'>
                                        {
                                            valuesSummary.pickoffLocation
                                        }
                                    </div>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                        RETURN PICKOFF LOCATION
                                    </div>
                                    <div className='summary-field-value'>
                                        {
                                            valuesSummary.pickupLocation
                                        }
                                    </div>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                        PICKUP DATE, TIME
                                    </div>
                                    <div className='summary-field-value'>
                                        {valuesSummary.pickupDateReturn}, {valuesSummary.pickupTimeReturn}
                                    </div>
                                </div>
                            </div>
                            </>
                            :
                            <></>
                        }
                    </div>
                    <div className='col-md-4 ml-2 mr-2'>
                        <div className='card-royal'>
                            <label className='label-group'>
                                Vehicle Info
                            </label>
                            <div className='chbs-summary'>
                                <div className='summary-fields'>
                                    {
                                        valuesVehicle.vehicle.idVehiculo === 1 ?
                                            <img className='summary-info' src={'https://royalpickupsrc.s3.amazonaws.com/van.webp'} alt='vehicle'/>
                                            :
                                            <img className='summary-info' src={'https://royalpickupsrc.s3.amazonaws.com/suburban.webp'} alt='vehicle'/>
                                    }

                                    <div className='summary-field-name'>
                                        Vehicle
                                    </div>
                                    <div className='summary-field-value'>
                                        {valuesVehicle.vehicle.vehicles} x {valuesVehicle.vehicle.nombreVehiculo}
                                    </div>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                    Passengers
                                    </div>
                                    <div className='summary-field-value'>
                                    { passengers } Passengers
                                    </div>
                                </div>
                                {
                                    valuesVehicle?.extras ?
                                    <div className='summary-fields'>
                                        <div className='summary-field-name'>
                                            Extra Options
                                        </div>
                                        <div className='summary-field-value'>
                                        {
                                            valuesVehicle?.extras?.map((item) => {
                                                return(
                                                    <div key={item.id}>
                                                        {item.cantidad} x {item.nombre}
                                                        <br></br>
                                                    </div>
                                                )
                                            })

                                        }
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                            </div>
                        </div>
                        <div className="clear-fix chbs-coupon-code-section">
                            <Accordion allowToggle>
                                <AccordionItem className='no-board'>
                                <h2>
                                    <AccordionButton>
                                    <AccordionIcon/>
                                    <Box flex='1' textAlign='left'>Do you have a discount code?</Box>
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                <div className="chbs-form-field">
                                    <label></label>
                                    <Textarea size='sm' value={code} onChange={(e) => setCode(e.target.value)}/>

                                </div>
                                <div className='mb-2 mt-3 d-grid gap-2 d-md-block btn-coupon' style={{textAlignLast: 'right'}}>
                                    <button className='chbs-button chbs-button-style-2' onClick={handleApplyCode}>APPLY CODE</button>
                                </div>
                                </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                        <div className='summary-price'>
                            <div className='price-vehicle'>
                                <span>Selected Vehicle</span>
                                <span>${valuesVehicle.vehicle.precioFinal}</span>
                                <br></br>
                                {
                                    totalExtra > 0 ?
                                    <>
                                    <span>Extra options</span>
                                    <span className='summary-price-extra'>${totalExtra}</span>
                                    </>:
                                    <></>
                                }
                            </div>
                            {
                                newTotal > 0 ?

                                <div className='price-vehicle'>
                                    <span>Discount</span>
                                    <span> - ${discount}</span>
                                        <br></br>
                                </div> :
                                <></>
                            }
                            <div className='price-total'>
                                {
                                    newTotal > 0 ?
                                    <>
                                        <span>Total</span>
                                        <span>${newTotal}</span>
                                    </>
                                        :
                                    <>
                                        <span>Total</span>
                                        <span>${total}</span>
                                    </>
                                }

                            </div>
                        </div>
                        <div className='mb-2 mt-3 d-grid gap-2 d-md-block payment-button'>
                        <button onClick={() => handleCheckout()}>Check out</button>

                            {
                                valuesContact.paymentMethod === 'Paypal'
                                    ? <>
                                        <PayPalScriptProvider options={{ "client-id": "AdUi0-BMxfJ7ABLUn9SplXKbkvrgDgOnAIGAFgMF-gQFhd_t9a-SeIdQmlzUX65HjZwB4bZ-Km4T_4O3" }}>
                                            <div>

                                                    <PayPalButtons
                                                        style={{ layout: "vertical" }}
                                                        createOrder={createOrder}
                                                        onApprove={onApprove}
                                                    />
                                            </div>
                                        </PayPalScriptProvider>
                                    </>
                                    : <button className='chbs-button chbs-button-style-2 selected-button' disabled={show} onClick={handleSubmit}>BOOK NOW</button>
                            }
                            {
                                Error ?
                                <Alert status='error' style={{marginBottom:'10px'}}>
                                    <AlertIcon />
                                    <AlertTitle>Error with Paypal!</AlertTitle>
                                    <AlertDescription>We have a error with Paypal, please try again.</AlertDescription>
                                </Alert>
                                : <></>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
