import { SimpleGrid, Stack ,Center, Box, Image, Heading, Text, Container } from "@chakra-ui/react";
import ChakraCarousel from '../ChakraCarousel/ChakraCarousel';
import { useNavigate  } from "react-router-dom";

function ServiceCard() {

    const history = useNavigate();

    const data = [{
        img: 'https://royalpickupsrc.s3.amazonaws.com/serviceimg/airport.webp',
        title: 'AIRPORT TRANSFER',
        section: 'We provide ',
        subsection: 'airport transfers ',
        description: "from all major airports. Our service is the perfect option for when you are travelling for pleasure or for business.",
    },
    {
        img: 'https://royalpickupsrc.s3.amazonaws.com/serviceimg/corporate.webp',
        title: 'CORPORATE TRAVEL',
        section: 'We provide ',
        subsection: 'business services ',
        description: "and our own vehicle fleet so that you can reach your destination safely, in comfort and always on time.",
    },{
        img: 'https://royalpickupsrc.s3.amazonaws.com/serviceimg/wedding.webp',
        title: "TAYLOR MADE SERVICE",
        section: 'Whether is a wedding event or a short shopping trip, we will get you there in comfort plus ',
        subsection: 'we will handle the logistics efficiently.',
        description: "",
    }];


    const handleBook = () => {
        history("/ride-details");
    }

    return(
        <div>
            <div className='container desktop-version'>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} spacingX='40px' spacingY='20px'>
                    {
                        data.map((item, idx) => {
                            return(
                                <Center py={6} key={idx}>
                                        <Box
                                            maxW={'445px'}
                                            w={'full'}
                                            boxShadow={'2xl'}
                                            rounded={'md'}
                                            p={6}
                                            overflow={'hidden'}
                                            className={'card-responsive-royal'}
                                            onClick={handleBook}>
                                            <Box
                                                h={'max-content'}
                                                bg={'gray.100'}
                                                mt={-6}
                                                mx={-6}
                                                mb={6}
                                                pos={'relative'}>
                                                <Image
                                                src={item.img}
                                                alt={item.title}
                                                layout={'fill'}
                                                />
                                            </Box>
                                            <Stack>
                                                <Heading
                                                    color={'#631042'}
                                                    fontSize={'2xl'}
                                                    fontFamily={'body'}
                                                    alignContent={'center'}
                                                    as={'h3'}
                                                    style={{alignSelf: 'center'}}>
                                                    {item.title}
                                                </Heading>
                                                <p>{item.section}
                                                    <strong>{item.subsection}</strong>
                                                {item.description}</p>
                                            </Stack>
                                        </Box>
                                </Center>
                            )
                        })
                    }
                </SimpleGrid>
            </div>
            <Container
                py={8}
                px={0}
                maxW={{
                base: "100%",
                sm: "35rem",
                md: "43.75rem",
                lg: "57.5rem",
                xl: "75rem",
                xxl: "87.5rem",
                }}
                className={"mobile-version"}
                >
                <ChakraCarousel gap={32}>
                    {data.map((item, index) => (
                        <Center py={6} key={index}>
                                <Box
                                    maxW={'445px'}
                                    w={'full'}
                                    boxShadow={'2xl'}
                                    rounded={'md'}
                                    p={6}
                                    overflow={'hidden'}
                                    onClick={handleBook}>
                                    <Box
                                        h={'210px'}
                                        bg={'gray.100'}
                                        mt={-6}
                                        mx={-6}
                                        mb={6}
                                        pos={'relative'}>
                                        <Image
                                        src={item.img}
                                        layout={'fill'}
                                        />
                                    </Box>
                                    <Stack>
                                        <Heading
                                            color={'#631042'}
                                            fontSize={'2xl'}
                                            fontFamily={'body'}
                                            alignContent={'center'}
                                            alignItems={'center'}
                                            as={'h3'}
                                            style={{alignSelf: 'center'}}>
                                            {item.title}
                                        </Heading>
                                        <Text color={'gray.500'} className={'card-body-chakra'}>
                                            {item.section} {item.subsection} {item.description}
                                        </Text>
                                    </Stack>
                                </Box>
                        </Center>
                    ))}
                </ChakraCarousel>
            </Container>
        </div>

    );

}

export default ServiceCard;
