import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    extras: []
}

const extrasSlice = createSlice({
    name: "extras",
    initialState: initialState,
    reducers: {
        addExtras: (state, action) => { state.extras = action.payload },
        clearExtras: (state) => { state = [] }
    }
})

const { reducer, actions } = extrasSlice;

export const { addExtras, clearExtras } = actions

export default reducer;
