import { Box, Container, SimpleGrid, Stack, Text, Flex, useColorModeValue } from '@chakra-ui/react';
import { FaInstagram, FaFacebookF, FaTripadvisor, FaYelp, FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa';
import Logo from "../Navbar/Logo";

const ListHeader = ({ children }) => {
    return (
      <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
        {children}
      </Text>
    );
};

  export default function LargeWithLogoCentered() {

  const redirectLink = (url) => {
    window.open(url, '_blank');
  }

    return (
      <Box
        className='footer-fixed'
        bg={'#641142'}
        color={'#ffffff'}>
        <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
            <Stack align={'flex-start'}>
              <ListHeader>About Royal Pickup</ListHeader>
                <span>A company decided to improve the transportation service in Mexico. We have offered service for more than 20 Years to very important Resorts and Agencies in Mexico. Now, all these is at your reach.</span>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>About Us</ListHeader>
                <p className='links-footer' onClick={() => redirectLink('https://www.royalpickup.com/about-us/')}>About us</p>
                <p className='links-footer' onClick={() => redirectLink('https://www.royalpickup.com/blog/')}>Blog</p>
                <p className='links-footer' onClick={() => redirectLink('https://www.royalpickup.com/privacy-policy/')}>Privacy Policy</p>
                <p className='links-footer' onClick={() => redirectLink('https://www.royalpickup.com/faqs/')}>FAQ's</p>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Need Help?</ListHeader>
              <Stack direction={'row'} spacing={6}>
              <a href = "mailto:sales@royalpickup.com?Subject=Information" style={{
                display:'flex'}}><FaRegEnvelope style={{marginTop: '5px',
                marginRight: '5px',}}/> sales@royalpickup.com</a>
              </Stack>
              <Stack direction={'row'} spacing={6}>
                <a href="tel:+529987348024" style={{display:'flex'}}> <FaPhoneAlt style={{marginTop: '5px',
                marginRight: '5px',}}/> +52 998 734 8024</a>
              </Stack>
            </Stack>
            <Stack align={'flex-start'}>
                <ListHeader>Follow Us</ListHeader>
                <Stack direction={'row'} spacing={6}>
                  <button className='btn rounded-circle' onClick={() => redirectLink('https://www.facebook.com/RoyalPickUp/')} style={{color: 'white'}}>
                    <FaFacebookF/>
                  </button>
                  <button className='btn rounded-circle' onClick={() => redirectLink('https://www.instagram.com/royalpickup/')} style={{color: 'white'}}>
                    <FaInstagram />
                  </button>
                </Stack>
                <span>REVIEWS</span>
                <Stack direction={'row'} spacing={6}>
                    <button className='btn rounded-circle button-footer' onClick={() => redirectLink('https://www.tripadvisor.com/Attraction_Review-g150807-d11969809-Reviews-Royal_PickUp-Cancun_Yucatan_Peninsula.html')}>
                      <FaTripadvisor className='icons-footer'/> <span>Tripadvisor</span>
                    </button>

                </Stack>
                <Stack direction={'row'} spacing={6}>
                    <button className='btn rounded-circle button-footer' onClick={() => redirectLink('https://www.yelp.com/biz/royal-pickup-canc%C3%BAn')}>
                      <FaYelp className='icons-footer'/> <span>Yelp</span>
                    </button>
                </Stack>
            </Stack>
          </SimpleGrid>
        </Container>
        <Box py={10}>
          <Flex
            align={'center'}
            _before={{
              content: '""',
              borderBottom: '1px solid',
              borderColor: useColorModeValue('gray.200', 'gray.700'),
              flexGrow: 1,
              mr: 8,
            }}
            _after={{
              content: '""',
              borderBottom: '1px solid',
              borderColor: useColorModeValue('gray.200', 'gray.700'),
              flexGrow: 1,
              ml: 8,
            }}>
            <Logo />
          </Flex>
          <Text pt={6} fontSize={'sm'} textAlign={'center'}>
            Smza. 63 Mza. 31 Lote 77513 Cancún, Q.R. Mexico
            <br></br>
            <small>Copyright © 1996–2023 royalpickup.com. Todos los derechos reservados.</small>
            <br/>
            <small>DESIGNED BY: BE MORE SOCIAL MARKETING</small>
          </Text>
        </Box>
      </Box>
    );
  }