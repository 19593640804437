import { Carousel, Card, Stack } from "react-bootstrap";
import { SimpleGrid } from "@chakra-ui/react";

function Partners(){

    const data = [
        {
            img: 'https://royalpickupsrc.s3.amazonaws.com/partners/1.webp',
            name: "The Ritz-Carlton"
        },
        {
            img: 'https://royalpickupsrc.s3.amazonaws.com/partners/2.webp',
            name: "BlueBay"
        },{
            img: 'https://royalpickupsrc.s3.amazonaws.com/partners/3.webp',
            name: "Sony"
        },{
            img: 'https://royalpickupsrc.s3.amazonaws.com/partners/4.webp',
            name: "Embajada de la India"
        },{
            img: 'https://royalpickupsrc.s3.amazonaws.com/partners/5.webp',
            name: "TV Azteca"
        },{
            img: 'https://royalpickupsrc.s3.amazonaws.com/partners/6.webp',
            name: "Blue Diamond"
        }
    ]

    const data1 = [{
        item:[
            {
                img: 'https://royalpickupsrc.s3.amazonaws.com/partners/1.webp',
                name: "The Ritz-Carlton"
            },
            {
                img: 'https://royalpickupsrc.s3.amazonaws.com/partners/2.webp',
                name: "BlueBay"
            },{
                img: 'https://royalpickupsrc.s3.amazonaws.com/partners/3.webp',
                name: "Sony"
            }
        ]
    },{
        item:[
            {
                img: 'https://royalpickupsrc.s3.amazonaws.com/partners/4.webp',
                name: "Embajada de la India"
            },{
                img: 'https://royalpickupsrc.s3.amazonaws.com/partners/5.webp',
                name: "TV Azteca"
            },{
                img: 'https://royalpickupsrc.s3.amazonaws.com/partners/6.webp',
                name: "Blue Diamond"
            }]
    }]

    return(
        <div>
            <div className='container desktop-version'>
                <Carousel>
                    {
                        data1.map((items, idx) => {
                            return(
                                <Carousel.Item key={idx}>
                                    <Stack
                                        direction="horizontal"
                                        className="h-100 justify-content-center align-items-center"
                                        gap={3}
                                    >
                                    {
                                        items.item.map((item, id) => {
                                            return(
                                                <Card  key={id}>
                                                    <img className="d-block w-100" src={item.img} alt={item.name}/>
                                                </Card>
                                            )
                                        })
                                    }
                                    </Stack>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
            <div className='container mobile-version'>
                <SimpleGrid columns={{ base: 2, md: 3, lg: 3 }} spacingX='40px' spacingY='20px'>
                    {
                        data.map((item, idx) => (<img key={idx} className="d-block w-100" src={item.img} alt={item.name}/>))
                    }
                </SimpleGrid>
            </div>
        </div>
    )
}

export default Partners;