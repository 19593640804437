import React from 'react';
import './App.css';
import LargeWithLogoCentered from './components/Footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { ChakraProvider } from '@chakra-ui/react';
import Booking from './pages/booking';
import { Login } from './pages/Auth/Login';
import { NavbarBos } from './components/Navbar/Navbar';
import { ResponsePayment } from './components/Payments/Response';
import { NotFound } from './pages/notfound';
import { Confirmation } from './pages/confirmation';
import { FormRide } from './pages/Booking/formRide';
import { FormVehicles } from './pages/Booking/formVehicles';
import { FormContact } from './pages/Booking/formContact';
import { FormCheckout } from './pages/Booking/formCheckout';
import Terms from './pages/terms';
import DestinationsPage from './pages/destinations';
import Faqs from './pages/faqs';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Cancun from './pages/Destinations/cancun';


export default function App() {
  return(
      <ChakraProvider>
        <PayPalScriptProvider options={{
            "client-id": "AdUi0-BMxfJ7ABLUn9SplXKbkvrgDgOnAIGAFgMF-gQFhd_t9a-SeIdQmlzUX65HjZwB4bZ-Km4T_4O3",
            components: "buttons",
            currency: "USD"
            }}>
          <BrowserRouter>
            <NavbarBos/>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/booking" element={<Booking/>}/>
              <Route path='/privacy-policy' element={<Terms/>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/confirm-payment' element={<ResponsePayment/>}/>
              <Route path='/order-confirmed' element={<Confirmation/>}/>
              <Route path='/ride-details' element={<FormRide/>}/>
              <Route path='/choose-vehicle' element={<FormVehicles/>}/>
              <Route path='/contact-details' element={<FormContact/>}/>
              <Route path='/summary' element={<FormCheckout/>}/>
              <Route path='/destinations' element={<DestinationsPage/>}/>
              <Route path='/faqs' element={<Faqs/>}/>
              <Route path='/destinations/cancun' element={<Cancun/>}/>
              <Route path="*" element={<NotFound />} />
            </Routes>
            <LargeWithLogoCentered/>
          </BrowserRouter>
        </PayPalScriptProvider>
      </ChakraProvider>
  )
}
