import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    formRide: {
        pickupDate: '',
        pickupTime: '12:00',
        pickupLocation: '',
        pickoffLocation: '',
        tranferType: 'One Way',
        noAdults: 2,
        noChildrens: 0,
        totalDistance: '',
        totalTime: '',
        pickupDateReturn: '',
        pickupTimeReturn: '12:00'
    },
    formVehicles: {},
    formContact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        comments: '',
        flightNumber: '',
        airline: '',
        notes: '',
        paymentMethod: "Cash On Arrival"
    },
    payment: false,
}

const rootSlice = createSlice({
    name: "root",
    initialState: initialState,
    reducers: {
        saveFormRide: (state, action) => { state.formRide = action.payload },
        saveFormVehicles: (state, action) => { state.formVehicles = action.payload },
        saveFormContact: (state, action) => { state.formContact = action.payload },
        updatePayment: (state, action) => { state.payment = action.payload},
        clearForms: (state) => { state = initialState }
    }
});

const { reducer, actions } = rootSlice;

export const { saveFormRide, saveFormVehicles, saveFormContact, updatePayment ,clearForms } = actions;

export default reducer;
