import React from "react";
import Partners from '../ComponentsV1/Carousel/Partners';
/* Componentes mejorados*/
import ServiceCard from '../ComponentsV1/Cards/Services';
import CardBooks from '../ComponentsV1/Cards/Books';
import { Maps } from "../components/Maps";

export const Home = () =>{

    const data = [{
        img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/cancun.webp',
        title: "Cancun Transfers",
        description: "Discovers white sand beaches light turquoise waters, crazy nights and cultural visits all in one city: Cancún"
    },{
        img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/Playa_del_carmen.webp',
        title: "Playa del Carmen Transfers",
        description: "Enjoy the charm of this town where luxury and entertainment meet."
    },{
        img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/tulum.webp',
        title: "Tulum Transfers",
        description: "Tulum is the host of unbelievable natural attractions that will amaze anyone."
    },{
        img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/puerto_morelos.webp',
        title: "Puerto Morelos Transfers",
        description: "Visit small fishing village that amazes travelers from being such a quiet place with picturesque streets."
    },{
        img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/puerto_aventuras.webp',
        title: "Puerto Aventuras Transfers",
        description: "Try all the diferent activities, Puerto Aventuras bring while being surrounded by crystalline waters, white fine sand and tropical vegetation."
    },{
        img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/akumal.webp',
        title: "Akumal Transfers",
        description: "Find unique landscapes in the middle of the Mayan jungle, cenotes and hotels."
    },{
        img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/playa_mujeres.webp',
        title: "Playa Mujeres Transfers",
        description: "Explore the beautiful beach of Isla Blanca or walk in the incredible Chacmuchuch lagoon."
    },{
        img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/holbox.webp',
        title: "Holbox Transfers",
        description: "Reach the port of Chiquilá and set sail to the natural beauty of the Holbox Island within the nature reserve of Yum Balam."
    }]

    return(
        <main style={{'flexGrow':1}}>
            <section>
                <div className='landing'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 order-sm-first order-last">
                                <Maps/>
                            </div>
                            <div className="col-lg-8 col-md-6 col-12 text-captions order-sm-first">
                                <h1 className='captions-royal'>Private Transfers in the Mexican Caribbean</h1>
                                <p className='subtitle-captions'>Book today and get 20% off in orders above $130usd.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sections'>
                <div>
                    <p className="title-gray">Welcome to Royal PickUp</p>
                    <h2 className="title-red">Our Services</h2>
                </div>
                <ServiceCard/>
            </section>
            <h3 className='titleSection'>
                WHY BOOK WITH ROYAL PICKUP?
            </h3>
            <section className='mt-4'>
                <CardBooks/>
            </section>
            <section>
                <h3 className='titleSection'>
                    OUR MOST FREQUENT DESTINATIONS
                </h3>
                <div className="container">
                    <div className="row">
                        {
                            data.map((item, idx) => (
                                <div className="col-6 col-md-3 mb-4" key={idx}>
                                    <div className="card h-100 border-0">
                                        <img src={item.img} className="card-img-top image-destinations" alt={item.title}/>
                                        <div className="card-body">
                                            <h2 className="card-title">{item.title}</h2>
                                            <hr className='divider'></hr>
                                            <p className="card-text">{item.description}</p>
                                        </div>
                                        <div className="card-buttons">
                                            <button className='chbs-button chbs-button-style-book selected-button'>BOOK NOW</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <section className="background-banner">
                <div className="container" style={{margin: '15px'}}>
                    <h2 style={{fontWeight: 'bold'}}>NEED A SAFE PRIVATE TRANSFER SERVICE? DO YOU WANT QUALITY IN VEHICLE AND SERVICE? YOU NOW WHAT TO DO!</h2>
                </div>
            </section>
            <section className='section mb-5 mt-5'>
                <h3 className='titleSection mb-4'>Our Partners</h3>
                <Partners/>
            </section>
        </main>
    )

}