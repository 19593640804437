import React from 'react';
import { Bookings } from '../ComponentsV1/StepForm/Booking';

const Booking = () => {

  return (
    <div className='container'>
      {
        /*
      <div class="banner">
        <div class="shopping-image">
          <img src="https://images.unsplash.com/photo-1534452203293-494d7ddbf7e0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1952&q=80" alt="shopping"/>
        </div>

        <div class="text">
          <h4>
            HOLIDAY SUPERSALE
          </h4>
        </div>
      </div>

        */
      }

      <Bookings/>
    </div>
  );
};

export default Booking;