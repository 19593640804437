import React, { useState, useEffect, useRef } from 'react';
import {
    Accordion, AccordionItem, AccordionButton,
    Box, AccordionIcon, AccordionPanel, Button,
    useToast, NumberInput, NumberInputField, NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper
} from '@chakra-ui/react';
import { FaUserFriends, FaLuggageCart, FaCartPlus } from 'react-icons/fa';
import { ChevronRightIcon } from '@chakra-ui/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import extras1 from '../../data/extras.json';

import { saveFormVehicles } from '../../app/rootSlice';
import { addExtras } from '../../app/extraSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Banner } from '../../ComponentsV1/Carousel/Banner';

export const FormVehicles = () => {

    const cars = useSelector((state) => state.car.cars);
    const extrasForm = useSelector((state) => state.extra.extras);
    const formCar = useSelector((state) => state.root.formVehicles);
    const formRide = useSelector((state) => state.root.formRide);
    const dispatch = useDispatch();
    const history = useNavigate();

    const [renderCars, setRenderCars] = useState([]);
    const ref = useRef(null);
    const toast = useToast()
    const toastIdRef = useRef();
    const myRef = useRef(null);
    const [extras, setExtras] = useState([]);
    const [formVehicles, setFormVehicles] = useState({});

    const executeScroll = () => {
        myRef.current.scrollIntoView()
    }

    const handleAdd = (extra) => {

        let newCart = [...extras];

        for(var i = 0; i <= extras.length -1; i ++){
          if(newCart[i].id === extra.id){
            let selected = newCart[i].select;
            newCart[i].select = !selected;
          }
        }

        setExtras(newCart);

    };

    const handleInput = (value, extra) => {
        let newCart = [...extras];

        for(var i = 0; i <= extras.length -1; i++){
            if(newCart[i].id === extra.id){
                if(value > newCart[i].cantidad){
                    newCart[i].cantidad ++;
                    setExtras(newCart);
                    return;
                }else{
                    newCart[i].cantidad --;
                    setExtras(newCart);
                    return;
                }
            }
        }

    };

    /**
    * Si el auto se ha seleccionado se remueve y se deselecciona el otro
    */
    const handleSelect = (car) => {

        const newCar = [...renderCars];

        for(var i = 0; i <= newCar.length -1; i++){
            if(newCar[i].id === car.id){
              newCar[i].selected = true;
            }else{
              newCar[i].selected = false;
            }
        }

        setRenderCars(newCar);
        setFormVehicles(car);
    };

    /**
     * Se valida que se haya seleccionado un auto
     */
    const handleNextStep = () => {

        let isValid = true;
        const selectedCar = renderCars.filter(item => item.selected === true);
        let extrasAvailable = [...extras];
        const extraSelected = extrasAvailable.filter(b => b.select === true);

        if(!selectedCar.length){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Select a car.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(isValid){
            dispatch(saveFormVehicles(formVehicles));
            dispatch(addExtras(extraSelected));
            history("/contact-details");
        }

    }

    const handleValidateCars = () => {

        const exist = formCar.hasOwnProperty("selected");
        const newCar = [...cars];
        const newArray = [];
        const arrayProperty = ["id", "Precio", "idVehiculo", "nombreVehiculo", "passengers", "precioFinal", "selected", "vehicles"];

        if(exist){
            for(var i = 0; i <= newCar.length -1; i++){
                let objectNew = {};
                // eslint-disable-next-line
                arrayProperty.map((idx) => {
                    Object.defineProperty(objectNew, idx, {
                        value: "",
                        writable: true,
                        enumerable: true,
                        configurable: true
                        });
                });
                objectNew["id"] = newCar[i].id;
                objectNew["Precio"] = newCar[i].Precio;
                objectNew["idVehiculo"] =  newCar[i].idVehiculo;
                objectNew["nombreVehiculo"] = newCar[i].nombreVehiculo;
                objectNew["passengers"] = newCar[i].passengers;
                objectNew["precioFinal"] = newCar[i].precioFinal;
                if(formCar.id === newCar[i].id){
                    objectNew["selected"] = true;
                }else{
                    objectNew["selected"] = false;
                }
                objectNew["vehicles"] = newCar[i].vehicles;
                newArray.push(objectNew);
            }

            setRenderCars(newArray.sort((x, y) => x.Precio - y.Precio));

        }else{
            for(var j = 0; j <= newCar.length -1; j++){
                let objectNew = {};
                // eslint-disable-next-line
                arrayProperty.map((idx) => {
                    Object.defineProperty(objectNew, idx, {
                        value: "",
                        writable: true,
                        enumerable: true,
                        configurable: true
                        });
                });
                objectNew["id"] = newCar[j].id;
                objectNew["Precio"] = newCar[j].Precio;
                objectNew["idVehiculo"] =  newCar[j].idVehiculo;
                objectNew["nombreVehiculo"] = newCar[j].nombreVehiculo;
                objectNew["passengers"] = newCar[j].passengers;
                objectNew["precioFinal"] = newCar[j].precioFinal;
                objectNew["selected"] = newCar[j].selected;
                objectNew["vehicles"] = newCar[j].vehicles;
                newArray.push(objectNew);
            }

            setRenderCars(newArray.sort((x, y) => x.Precio - y.Precio));
        }
    };

    const handleValidateExtras = () => {

        const newExtras = [...extras1];
        const newExtrasForm = [...extrasForm];
        const newArrayExtra = [];
        const extraProperty = ["id", "nombre","descripcion","precio","select","cantidad"]

        if(extrasForm.length > 0){

            for(var i = 0; i <= newExtras.length -1; i++){
                let objectNew = {};
                // eslint-disable-next-line
                extraProperty.map((idx) => {
                    Object.defineProperty(objectNew, idx, {
                        value: "",
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                });
                objectNew["id"] = newExtras[i].id;
                objectNew["nombre"] = newExtras[i].nombre;
                objectNew["descripcion"] =  newExtras[i].descripcion;
                objectNew["precio"] = newExtras[i].precio;
                objectNew["select"] = newExtras[i].select;
                objectNew["cantidad"] = newExtras[i].cantidad;
                objectNew["select"] = false;
                for(var j = 0; j <= newExtrasForm.length -1; j++){
                    if(newExtras[i].id === newExtrasForm[j].id){
                        objectNew["select"] = true;
                    }
                }
                newArrayExtra.push(objectNew);
            }
            setExtras(newArrayExtra);
        }else{
            setExtras(newExtras);
        }

    };

    const returnPage = (url) => {
        history(url);
    };

    useEffect(() => {
        if(formRide.pickupLocation === ""){
            history("/ride-details");
        }
        handleValidateCars();
        handleValidateExtras();
        window.scroll({
            top: 5,
            behavior: "smooth"
        });
    // eslint-disable-next-line
    },[cars]);

    return(
        <>
            <Banner/>
            <div className='container mt-1'>
                <div className='row'>
                    <div ref={ref} id="stepper1" className="bs-stepper desktop-version">
                        <div className="bs-stepper-header">
                            <div className="step" data-target="#test-l-1">
                            <button className="step-trigger" onClick={() => returnPage("/ride-details")}>
                                <span className="bs-stepper-circle active-step">1</span>
                                <span className="bs-stepper-label">Enter Ride Details</span>
                            </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#test-l-2">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle active-step">2</span>
                                <span className="bs-stepper-label">Choose a Vehicle</span>
                            </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#test-l-3">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle">3</span>
                                <span className="bs-stepper-label">Enter Contact Details</span>
                            </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#test-l-3">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle">4</span>
                                <span className="bs-stepper-label">Checkout</span>
                            </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-12 ml-2 mr-5'>
                        <div className='chbs-vehicle-list'>
                            <ul className='chbs-list-reset'>
                                {
                                    renderCars?.map((item) => {
                                        return(
                                            <li key={item.id}>
                                                <div className="card mb-3 no-board">
                                                    <div className="row g-0">
                                                        <div className="col-md-4">
                                                            <div className='chbs-vehicle-image'>
                                                                {
                                                                    item.idVehiculo === 1 ? <img src={'https://royalpickupsrc.s3.amazonaws.com/van.webp'} alt='vehicle'/> : <img src={'https://royalpickupsrc.s3.amazonaws.com/suburban.webp'} alt='vehicle'/>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="card-body">
                                                                <div className='row'>
                                                                    <div className='col-lg-6 col-md-6 col-12 title-card-vehicles'>
                                                                        <span className='chbs-vehicle-carname'>{item.nombreVehiculo} - {item.vehicles} Unit(s)</span>
                                                                    </div>
                                                                    <div className='col-lg-6 col-md-6 col-12 mb-2 mt-3 d-grid gap-2 d-md-block' style={{textAlignLast: 'center'}}>
                                                                        <button className={item.selected ? 'chbs-button chbs-button-style-2 selected-button':'chbs-button chbs-button-style-2'} onClick={() => handleSelect(item)}>SELECT</button>
                                                                    </div>
                                                                    <div className="chbs-vehicle-content-price">
                                                                        <span>
                                                                            <span>${item.precioFinal} USD</span>
                                                                            <span>Total for { item.passengers } passengers in {item.vehicles} vehicle(s)</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-md-12 mb-2'>
                                                                        <div className="chbs-vehicle-content-meta no-board">
                                                                            <Accordion allowToggle>
                                                                                <AccordionItem className='no-board'>
                                                                                    <h2>
                                                                                        <AccordionButton>
                                                                                        <AccordionIcon/>
                                                                                            <Box flex='1' textAlign='left'>MORE INFO</Box>
                                                                                        </AccordionButton>
                                                                                    </h2>
                                                                                        <AccordionPanel pb={4}>
                                                                                            {
                                                                                                item.idVehiculo === 1
                                                                                                    ?
                                                                                                    <>
                                                                                                        <strong>Service Details:</strong>
                                                                                                        <ul className='list-bullet'>
                                                                                                            <li>Private service.</li>
                                                                                                            <li>Flight monitoring.</li>
                                                                                                            <li>Pickup at the door of your arrival terminal.</li>
                                                                                                            <li>Passenger coverage insurance.</li>
                                                                                                            <li>Unlimited changes to the reservation.</li>
                                                                                                            <li>Free cancellation 48 hrs in advance.</li>
                                                                                                        </ul>
                                                                                                        <strong>Vehicle Details:</strong>
                                                                                                        <ul className='list-bullet'>
                                                                                                            <li>All spacious and comfortable.</li>
                                                                                                            <li>Large vehicle to seat up to 8 passengers with room for cargo. Ideal for large groups.</li>
                                                                                                        </ul>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <strong>Service Details:</strong>
                                                                                                        <ul className='list-bullet'>
                                                                                                            <li>Private service.</li>
                                                                                                            <li>Flight monitoring.</li>
                                                                                                            <li>Pickup at the door of your arrival terminal.</li>
                                                                                                            <li>Guaranteed punctually.</li>
                                                                                                            <li>Passenger coverage insurance.</li>
                                                                                                            <li>Unlimited changes to the reservation.</li>
                                                                                                            <li>Free cancellation 48 hrs in advance.</li>
                                                                                                        </ul>
                                                                                                        <strong>Vehicle Details:</strong>
                                                                                                        <ul className='list-bullet'>
                                                                                                            <li>All luxurious and comfortable.</li>
                                                                                                            <li>All spacious and comfortable.</li>
                                                                                                            <li>Extra large vehicle to seat up to 5 passengers in three rows of seating and room for cargo.</li>
                                                                                                            <li>Ahead of their class onboard equipment: surround sound system and LCD entertainment touch screen.</li>
                                                                                                        </ul>
                                                                                                    </>

                                                                                            }
                                                                                        </AccordionPanel>
                                                                                    </AccordionItem>
                                                                            </Accordion>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-12 col-lg-12 col-12'>
                                                                        <div className="chbs-vehicle-content-meta-info">
                                                                            <div className='row'>
                                                                                {
                                                                                    item.idVehiculo === 1
                                                                                    ?
                                                                                    <>
                                                                                        <div className='col-6 col-md-6 col-lg-6' style={{textAlignLast:'center'}}>
                                                                                            <span>Maximum Passengers Capacity</span>
                                                                                            <div style={{marginTop:"10px", display:"flex", justifyContent:'center'}}>
                                                                                                <span className="chbs-meta-icon-people"><FaUserFriends/></span>
                                                                                                <span className="chbs-circle">8</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-6 col-md-6 col-lg-6' style={{textAlignLast:'center'}}>
                                                                                            <span>Maximum Baggage Capacity</span>
                                                                                            <div style={{marginTop:"10px", display:"flex", justifyContent:'center'}}>
                                                                                                <span className="chbs-meta-icon-people"><FaLuggageCart/></span>
                                                                                                <span className="chbs-circle">8</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className='col-6 col-md-6 col-lg-6' style={{textAlignLast:'center'}}>
                                                                                            <span>Maximum Passengers Capacity</span>
                                                                                            <div style={{marginTop:"10px", display:"flex", justifyContent:'center'}}>
                                                                                                <span className="chbs-meta-icon-people"><FaUserFriends/></span>
                                                                                                <span className="chbs-circle">5</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-6 col-md-6 col-lg-6' style={{textAlignLast:'center'}}>
                                                                                            <span>Maximum Baggage Capacity</span>
                                                                                            <div style={{marginTop:"10px", display:"flex", justifyContent:'center'}}>
                                                                                                <span className="chbs-meta-icon-people"><FaLuggageCart/></span>
                                                                                                <span className="chbs-circle">5</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-12 ml-2 mr-5'>
                        <div className="chbs-booking-extra">
                            <div className="chbs-state-open">
                                <div className="chbs-booking-extra-list">
                                    <Accordion allowToggle>
                                        <AccordionItem className='no-board'>
                                        <h2>
                                            <AccordionButton>
                                                <div style={{marginTop:"10px", display:"flex"}}>
                                                    <span className="chbs-meta-icon-people" ref={myRef}><FaCartPlus/></span>
                                                    <span style={{color: "#631042", marginLeft:"15px"}}>Extra options</span>
                                                    <AccordionIcon/>
                                                </div>
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <ul className="chbs-list-reset">
                                                {
                                                    extras?.map((item) => {
                                                        return(
                                                            <li key={item.id} className="chbs-booking-extra-list-item-quantity-enable" data-category_id="" data-vehicle_id="-1">
                                                                <div className='row'>
                                                                    <div className='col-md-8 chbs-column-1'>
                                                                        <span className="chbs-booking-form-extra-name">
                                                                            { item.nombre }
                                                                        </span>
                                                                        <span className="chbs-booking-form-extra-price">
                                                                            ${ item.precio }
                                                                        </span>
                                                                        <span className="chbs-booking-form-extra-description">
                                                                            { item.descripcion }
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-md-2 chbs-column-2'>
                                                                        <NumberInput onChange={(e) => handleInput(e, item)} size='lg' defaultValue={item.cantidad} min={1}>
                                                                            <NumberInputField focusBorderColor='red.200' />
                                                                                <NumberInputStepper>
                                                                                    <NumberIncrementStepper children='+'/>
                                                                                    <NumberDecrementStepper children='-'/>
                                                                                </NumberInputStepper>
                                                                        </NumberInput>
                                                                    </div>
                                                                    <div className='col-md-2 chbs-column-3 d-grid gap-2 d-md-block'>
                                                                        <button className={item.select ? 'chbs-button chbs-button-style-2 selected-button':'chbs-button chbs-button-style-2'} onClick={() => handleAdd(item)}>Select</button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </AccordionPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{textAlign:'-webkit-right', paddingBottom:'50px', paddingTop: '50px'}}>
                    <Button
                        mr={4}
                        className='navigation-button button-booking'
                        border='1px'
                        textTransform='uppercase'
                        backgroundColor='#660C49'
                        fontSize='14px'
                        borderRadius='15px'
                        color='#ffffff'
                        rightIcon={<ChevronRightIcon w={8} h={8}/>}
                        colorScheme='teal'
                        variant='outline'
                        _hover={{ bg: '#FFFFFF', color:'#660C49' }}
                        size="sm"
                        onClick={handleNextStep}>
                        Enter Contact Details
                    </Button>
                </div>
            </div>
        </>
    );
};

