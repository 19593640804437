import { Box, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

export const ResponsePayment = () => {
    return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Your payment was successful
      </Heading>
      <Text color={'gray.500'}>
        Thank you for your payment. We will be in contact with more details shortly.
      </Text>
    </Box>
  );
}
