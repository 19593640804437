import { useState } from "react";
import { Checkout } from "../../components/Payments/Checkout";
import { FormRide } from "../Forms/FormRide";
import { FormSummary } from "../Forms/FormSummary";
import { FormVehicles } from "../Forms/FormVehicles";
import { format } from 'date-fns';

export const Bookings = () => {

    const [step, setStep] = useState(1);
    const [nopasajeros, setnopasajeros] = useState(0);
    const fecha = new Date();
    fecha.setDate(fecha.getDate() + 2);
    const [formRide, setFormRide] = useState({
        pickupDate: format(fecha, 'MM-dd-y'),
        pickupTime: '12:00',
        pickupLocation: '',
        pickoffLocation: '',
        tranferType: 'One Way',
        noAdults: 2,
        noChildrens: 0,
        totalDistance: '',
        totalTime: '',
        pickupDateReturn: '',
        pickupTimeReturn: '12:00'
    });
    const [formVehicles, setFormVehicles] = useState({
        vehicle: {},
        extras: [],
    });
    const [formContact, setFormContact] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        comments: '',
        flightNumber: '',
        airline: '',
        notes: '',
        paymentMethod: "Cash On Arrival"
    });
    const [cars, setCars] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalExtra, setTotalExtra] = useState(0);

    const nextStep = () => {

        if(step === 1){
            var adults = formRide.noAdults;
            var kids = formRide.noChildrens;
            var roundType = formRide.tranferType;
            const passengers = Number(adults) + Number(kids);
            setnopasajeros(passengers);
            var totalVans = passengers / 8;
            var totalSuburban = passengers / 5;
            var totalV = Math.ceil(totalVans);
            var totalS = Math.ceil(totalSuburban);
            const vehicles = cars;

            for(var i = 0; i <= vehicles.length -1; i ++ ){
                vehicles[i].selected = false;
                if(vehicles[i].idVehiculo === 1){
                    let total = totalV * vehicles[i].Precio;
                    vehicles[i].vehicles = totalV;
                    vehicles[i].precioFinal = total;
                }
                if(vehicles[i].idVehiculo === 2){
                    let total = totalS * vehicles[i].Precio;
                    vehicles[i].vehicles = totalS;
                    vehicles[i].precioFinal = total;
                }
            }

            if(roundType === 'Round Trip'){
                for(var j = 0; j <= vehicles.length -1; j ++ ){
                    vehicles[j].selected = false;
                    if(vehicles[j].idVehiculo === 1){
                        let newTotal = 2 * vehicles[j].precioFinal;
                        vehicles[j].vehicles = totalV;
                        vehicles[j].precioFinal = newTotal;
                    }
                    if(vehicles[j].idVehiculo === 2){
                        let newTotal = 2 * vehicles[j].precioFinal;
                        vehicles[j].vehicles = totalS;
                        vehicles[j].precioFinal = newTotal;
                    }
                }
            }
            setCars(vehicles);
        }

        if(step === 2){
            handlePrices();

        }

        if(step === 3){
            handlePrices();
        }

        setStep(step + 1 );

    }

    /**
     * Nuevos pasos
     */

    const prevSteps = (number) => {
        setStep(number);
    }

    const handleFormRide = input => e => {
        const { value } = e.target;

        setFormRide(prevState => ({
            ...prevState,
            [input]: value
        }));
    }

    const handleFormRideDate = (input, value) => {

        setFormRide(prevState => ({
            ...prevState,
            [input]: value
        }));
    }

    const handleFormData = (value, input) => {

        setFormRide(prevState => ({
            ...prevState,
            [input]: value
        }));

    }

    const handleVehicle = (input, value) => {
        setFormVehicles(prevState => ({
            ...prevState,
            [input]: value
        }));
    }

    const handleContact = input => e => {
        const { value } = e.target;

        setFormContact(prevState => ({
            ...prevState,
            [input]: value
        }));
    }

    const handlePayment = (input, value) => {
        setFormContact(prevState => ({
            ...prevState,
            [input]: value
        }))
    }

    const handleContactNumber = (input, value) => {
        setFormContact(prevState => ({
            ...prevState,
            [input]: value
        }))
    }

    //Precios de autos
    const handleSetCar = (value) => {
        setCars(value);
    }

    const handlePrices = () => {

        const carPrice = formVehicles.vehicle;
        const precio = carPrice['precioFinal'];
        const extras = formVehicles.extras;
        const totalExtra = extras.reduce((acum, item) => acum + (item.cantidad * item.precio), 0)
        setTotalExtra(totalExtra);
        const total = totalExtra + precio;
        setTotal(total);

    }

    switch(step){

        case 1:
            return(
                <FormRide nextStep={nextStep} handleFormRide={handleFormRide} values={formRide} handleFormData={handleFormData} handleSetCar={handleSetCar} handleFormRideDate={handleFormRideDate}/>
            )
        case 2:
            return(
                <FormVehicles nextStep={nextStep} prevSteps={prevSteps} handleVehicle={handleVehicle} carsprice={cars} valuesRide={formRide} passengers={nopasajeros}/>
            )
        case 3:
            return(
                <FormSummary nextStep={nextStep} prevSteps={prevSteps} handleContact={handleContact} values={formContact} handlePayment={handlePayment} handleContactNumber={handleContactNumber}/>
            )
        case 4:
            return(
                <Checkout nextStep={nextStep} prevSteps={prevSteps} valuesVehicle={formVehicles} total={total} totalExtra={totalExtra} passengers={nopasajeros} valuesSummary={formRide} valuesContact={formContact}/>
            )
        default:
            return(
                <></>
            )

    }

}
