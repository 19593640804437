import React, { useState, useEffect, useRef } from 'react';
import {
    Accordion, AccordionItem, AccordionButton,
    Box, AccordionIcon, AccordionPanel, Button,
    useToast, NumberInput, NumberInputField, NumberInputStepper,
    NumberIncrementStepper, NumberDecrementStepper
} from '@chakra-ui/react';
import { FaUserFriends, FaLuggageCart, FaCartPlus } from 'react-icons/fa';
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import extras1 from '../../data/extras.json';


export const FormVehicles = ({nextStep, prevSteps, handleVehicle, carsprice, passengers}) => {

    const [renderCars, setRenderCars] = useState([]);
    const ref = useRef(null);
    const toast = useToast()
    const toastIdRef = useRef();
    const myRef = useRef(null);
    const [extras, setExtras] = useState([]);

    const executeScroll = () => {
        myRef.current.scrollIntoView()
    }

    const handleAdd = (extra) => {

        let newCart = [...extras];

        for(var i = 0; i <= extras.length -1; i ++){
          if(newCart[i].id === extra.id){
            let selected = newCart[i].select;
            newCart[i].select = !selected;
          }
        }

        setExtras(newCart);

    };

    const handleInput = (value, extra) => {
        let newCart = [...extras];

        for(var i = 0; i <= extras.length -1; i++){
            if(newCart[i].id === extra.id){
                if(value > newCart[i].cantidad){
                    newCart[i].cantidad ++;
                    setExtras(newCart);
                    return;
                }else{
                    newCart[i].cantidad --;
                    setExtras(newCart);
                    return;
                }
            }
        }

    };

    /**
    * Si el auto se ha seleccionado se remueve y se deselecciona el otro
    */
    const handleSelect = (car) => {

        let newCar = [...renderCars];

        for(var i = 0; i <= renderCars.length -1; i++){
            if(newCar[i].id === car.id){
              newCar[i].selected = true;
            }else{
              newCar[i].selected = false;
            }
        }

        setRenderCars(newCar);
        handleVehicle('vehicle',car);
        executeScroll();
    };

    /**
     * Se valida que se haya seleccionado un auto
     */
    const handleNextStep = () => {

        let isValid = true;
        const selectedCar = renderCars.filter(item => item.selected === true);

        let extrasAvailable = [...extras];
        const extraSelected = extrasAvailable.filter(b => b.select === true);
        handleVehicle('extras', extraSelected);

        if(!selectedCar.length){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Select a car.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(isValid){
            nextStep();
        }

    }

    useEffect(() => {
        setRenderCars(carsprice);
        setExtras(extras1);
        window.scroll({
            top: 5,
            behavior: "smooth"
        });
    },[carsprice]);

    return(
        <div className='container mt-5'>
            <div className='row'>
                <div ref={ref} id="stepper1" className="bs-stepper desktop-version">
                    <div className="bs-stepper-header">
                        <div className="step" data-target="#test-l-1">
                        <button className="step-trigger" onClick={() => prevSteps(1)}>
                            <span className="bs-stepper-circle active-step">1</span>
                            <span className="bs-stepper-label">Enter Ride Details</span>
                        </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-2">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle active-step">2</span>
                            <span className="bs-stepper-label">Choose a Vehicle</span>
                        </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-3">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle">3</span>
                            <span className="bs-stepper-label">Enter Contact Details</span>
                        </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-3">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle">4</span>
                            <span className="bs-stepper-label">Checkout</span>
                        </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 col-lg-12 ml-2 mr-5'>
                    <div className='chbs-vehicle-list'>
                        <ul className='chbs-list-reset'>
                            {
                                renderCars?.map((item) => {
                                    return(
                                        <li key={item.id}>
                                            <div className="card mb-3 no-board">
                                                <div className="row g-0">
                                                    <div className="col-md-4">
                                                        <div className='chbs-vehicle-image'>
                                                            {
                                                                item.idVehiculo === 1 ? <img src={'https://royalpickupsrc.s3.amazonaws.com/van.webp'} alt='vehicle'/> : <img src={'https://royalpickupsrc.s3.amazonaws.com/suburban.webp'} alt='vehicle'/>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <span className='chbs-vehicle-carname'>{item.nombreVehiculo} - {item.vehicles} Unit(s)</span>
                                                                </div>
                                                                <div className='col-md-6 mb-2 mt-3 d-grid gap-2 d-md-block' style={{textAlignLast: 'center'}}>
                                                                    <button className={item.selected ? 'chbs-button chbs-button-style-2 selected-button':'chbs-button chbs-button-style-2'} onClick={() => handleSelect(item)}>SELECT</button>
                                                                </div>
                                                                <div className="chbs-vehicle-content-price">
                                                                    <span>
                                                                        <span>${item.precioFinal} USD</span>
                                                                        <span>Total for { passengers } passengers in {item.vehicles} vehicle(s)</span>
                                                                    </span>
                                                                </div>
                                                                <div className='col-md-12 mb-2'>
                                                                    <div className="chbs-vehicle-content-meta no-board">
                                                                        <Accordion allowToggle>
                                                                            <AccordionItem className='no-board'>
                                                                                <h2>
                                                                                    <AccordionButton>
                                                                                    <AccordionIcon/>
                                                                                        <Box flex='1' textAlign='left'>MORE INFO</Box>
                                                                                    </AccordionButton>
                                                                                </h2>
                                                                                    <AccordionPanel pb={4}>
                                                                                        {
                                                                                            item.idVehiculo === 1
                                                                                                ?
                                                                                                <>Equipped with comfortable seating, climate control, and wifi to provide a comfortable and enjoyable ride for passengers. Additional can be purchased like like bottled water, snacks, and charging stations for electronic devices. Convenient and stylish way to travel to and from the airport in comfort and style.</>
                                                                                                :
                                                                                                <>Our SUVs are equipped with all the latest features to make your trip as enjoyable as possible. From plush leather seats to state-of-the-art entertainment systems, you'll have everything you need to relax and unwind during your journey. And with plenty of room for luggage, you'll have all the space you need for all your travel essentials.
                                                                                                </>

                                                                                        }
                                                                                    </AccordionPanel>
                                                                                </AccordionItem>
                                                                        </Accordion>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-12'>
                                                                    <div className="chbs-vehicle-content-meta-info">
                                                                        <div className='row'>
                                                                            {
                                                                                item.idVehiculo === 1
                                                                                ?
                                                                                <>
                                                                                    <div className='col-md-6 col-6'>
                                                                                        <span className="chbs-meta-icon-people"><FaUserFriends/></span>
                                                                                        <span className="chbs-circle">8</span>
                                                                                    </div>
                                                                                    <div className='col-md-6 col-6'>
                                                                                        <span className="chbs-meta-icon-people"><FaLuggageCart/></span>
                                                                                        <span className="chbs-circle">8</span>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div className='col-md-6 col-6'>
                                                                                        <span className="chbs-meta-icon-people"><FaUserFriends/></span>
                                                                                        <span className="chbs-circle">5</span>
                                                                                    </div>
                                                                                    <div className='col-md-6 col-6'>
                                                                                        <span className="chbs-meta-icon-people"><FaLuggageCart/></span>
                                                                                        <span className="chbs-circle">5</span>
                                                                                    </div>
                                                                                </>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className='col-md-12 col-lg-12 ml-2 mr-5'>
                    <div className="chbs-booking-extra">
                        <h4 className="chbs-booking-extra-header">
                            <span className="chbs-meta-icon-people" ref={myRef}><FaCartPlus/></span>
                            <span>Extra options</span>
                        </h4>
                        <div className="chbs-state-open">
                            <div className="chbs-booking-extra-list">
                                <ul className="chbs-list-reset">
                                    {
                                        extras?.map((item) => {
                                            return(
                                                <li key={item.id} className="chbs-booking-extra-list-item-quantity-enable" data-category_id="" data-vehicle_id="-1">
                                                    <div className='row'>
                                                        <div className='col-md-8 chbs-column-1'>
                                                            <span className="chbs-booking-form-extra-name">
                                                                { item.nombre }
                                                            </span>
                                                            <span className="chbs-booking-form-extra-price">
                                                                ${ item.precio }
                                                            </span>
                                                            <span className="chbs-booking-form-extra-description">
                                                                { item.descripcion }
                                                            </span>
                                                        </div>
                                                        <div className='col-md-2 chbs-column-2'>
                                                            <NumberInput onChange={(e) => handleInput(e, item)} size='lg' defaultValue={item.cantidad} min={1}>
                                                                <NumberInputField focusBorderColor='red.200' />
                                                                    <NumberInputStepper>
                                                                        <NumberIncrementStepper children='+'/>
                                                                        <NumberDecrementStepper children='-'/>
                                                                    </NumberInputStepper>
                                                            </NumberInput>
                                                        </div>
                                                        <div className='col-md-2 chbs-column-3 d-grid gap-2 d-md-block'>
                                                            <button className={item.select ? 'chbs-button chbs-button-style-2 selected-button':'chbs-button chbs-button-style-2'} onClick={() => handleAdd(item)}>Select</button>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div style={{paddingBottom:'50px', paddingTop: '50px'}} className='button-navigate'>
                <Button
                    mr={4}
                    className='navigation-button button-booking'
                    border='1px'
                    textTransform='uppercase'
                    backgroundColor='#660C49'
                    fontSize='14px'
                    borderRadius='15px'
                    color='#ffffff'
                    leftIcon={<ChevronLeftIcon w={8} h={8}/>}
                    colorScheme='teal'
                    variant='outline'
                    _hover={{ bg: '#FFFFFF', color:'#660C49' }}
                    size="sm"
                    onClick={() => prevSteps(1)}>
                    Enter Ride Details
                </Button>
                <Button
                    mr={4}
                    className='navigation-button button-booking'
                    border='1px'
                    textTransform='uppercase'
                    backgroundColor='#660C49'
                    fontSize='14px'
                    borderRadius='15px'
                    color='#ffffff'
                    rightIcon={<ChevronRightIcon w={8} h={8}/>}
                    colorScheme='teal'
                    variant='outline'
                    _hover={{ bg: '#FFFFFF', color:'#660C49' }}
                    size="sm"
                    onClick={handleNextStep}>
                    Enter Contact Details
                </Button>
            </div>
        </div>
    );
};
