import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

export const NavbarBos = () => {

    return(
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <Link to={'/'} className="navbar-brand">
                    <img src="https://royalpickupsrc.s3.amazonaws.com/logo-royal.webp" className="logo-royal" alt="logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse" style={{textAlign: "center"}}>
                    <div className="navbar-nav ms-auto">
                        <div className="nav-item dropdown nav-item-royal" style={{textAlignLast: "center"}}>
                            <Link to={'#'} className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Destinations</Link>
                            <div className="dropdown-menu">
                                <Link to={'/destinations'} className="dropdown-item"><span>CANCÚN</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>PUERTO JUAREZ</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>PLAYA DEL CARMEN</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>PLAYA MUJERES</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>PUERTO MORELOS</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>PUERTO AVENTURAS</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>BAHÍA PRÍNCIPE</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>AKUMAL</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>TULUM</span></Link>
                                <Link to={'/destinations'} className="dropdown-item"><span>HOLBOX</span></Link>
                            </div>
                        </div>
                        <Link to={'#'} className="nav-item nav-link nav-item-royal" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Taylor Made</Link>
                        <Link to={'#'} className="nav-item nav-link nav-item-royal" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Contact Us</Link>
                    </div>
                </div>
            </div>
        </nav>
    )
}
