
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe("pk_test_RGgogE7Q5WFJK7j24R9Sg4IX003X8bLsUb");


export const StripeCheckout = () => {

    const [clientSecret, setClientSecret] = useState("");

    const appereance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appereance,
    };

    const items = [
        {
            "id": "agfasfasfasf",
            "amount": 250
        },
        {
            "id": "extras",
            "amount": 10
        }
    ]

    useEffect(() => {
        fetch("http://localhost:5000/create-payment-intent", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ items: items }),
        })
          .then((res) => res.json())
          .then((data) => setClientSecret(data.clientSecret));
        // eslint-disable-next-line
    }, []);

    return(
        <>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </>
    )

}