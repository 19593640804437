import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cars: []
}

const carSlice = createSlice({
    name: "cars",
    initialState: initialState,
    reducers: {
        addCars: (state, action) => { state.cars = action.payload },
        clearCars: (state) => { state.cars = [] }
    }
})

const { reducer, actions } = carSlice;

export const { addCars, clearCars } = actions

export default reducer;
