import { useEffect, useRef, useState } from 'react';
import { Button, Box, Image, useToast, useRadio, useRadioGroup, HStack, Stack, chakra, Text } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


export const FormSummary = ({nextStep, prevSteps, handleContact, values, handlePayment, handleContactNumber}) => {

    function CustomRadio(props) {

        const { image, payname,...radioProps } = props
        const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } = useRadio(radioProps)

        return (
            <chakra.label {...htmlProps} cursor='pointer'>
              <input {...getInputProps({})} hidden />
              <Box
                {...getCheckboxProps()}
                bg={state.isChecked ? '#f7e8ed' : 'transparent'}
                borderWidth='1px'
              >
                <Image src={image} {...getLabelProps()} />
              </Box>
            </chakra.label>
          )
    }

    const ref = useRef(null);
    const toast = useToast()
    const toastIdRef = useRef();
    const [phoneNumber, setValue] = useState()

    const options = [
        {
            id: 1,
            name:'Cash On Arrival',
            image: 'https://royalpickupsrc.s3.amazonaws.com/payments/casharrival.webp'
        },
        {
            id: 2,
            name: 'Credit/Debit Card On Arrival',
            image: 'https://royalpickupsrc.s3.amazonaws.com/payments/paycardarrival.webp'
        },
        {
            id: 3,
            name: 'Paypal',
            image: 'https://royalpickupsrc.s3.amazonaws.com/payments/Paypal.webp'
        }
    ]

    const handleChange = (value) => {
        handlePayment('paymentMethod', value)
    }

    const handleChangeNumber = (value) => {
        setValue(value);
        handleContactNumber('phone', value);
    }

    const { value, getRadioProps, getRootProps } = useRadioGroup({
        defaultValue: 'Cash On Arrival',
        onChange: handleChange,
    })

    const handleNextStep = () => {

        let isValid = true;

        const firstName = values.firstName;
        const lastName = values.lastName;
        const email = values.email;
        const phone = values.phone;

        if(firstName === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid First Name.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(lastName === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid Last Name.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(email === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid email.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(phone === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid Phone.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(isValid){
            nextStep();
        }

    }

    useEffect(() => {
        window.scroll({
            top: 5,
            behavior: "smooth"
        });
    },[])

    return(
    <div className='container mt-5'>
        <div className='row'>
            <div ref={ref} id="stepper1" className="bs-stepper desktop-version">
                <div className="bs-stepper-header">
                    <div className="step" data-target="#test-l-1">
                        <button className="step-trigger" onClick={() => prevSteps(1)}>
                            <span className="bs-stepper-circle active-step">1</span>
                            <span className="bs-stepper-label">Enter Ride Details</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-2">
                        <button className="step-trigger" onClick={() => prevSteps(2)}>
                            <span className="bs-stepper-circle active-step">2</span>
                            <span className="bs-stepper-label">Choose a Vehicle</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-3">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle active-step">3</span>
                            <span className="bs-stepper-label">Enter Contact Details</span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-3">
                        <button className="step-trigger">
                            <span className="bs-stepper-circle">4</span>
                            <span className="bs-stepper-label">Checkout</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className='col-md-6 col-lg-6'>
                <div className='card-royal'>
                    <label className='label-group'>
                        Contact details
                    </label>
                    <div className='clear-fix'>
                        <div className='form-field-2'>
                            <label>First Name *</label>
                            <input type="text" className='input-royal' value={values.firstName} onChange={handleContact('firstName')}/>
                        </div>
                        <div className='form-field-2'>
                            <label>Last Name *</label>
                            <input type="text" className='input-royal' value={values.lastName} onChange={handleContact('lastName')}/>
                        </div>
                    </div>
                    <div className='form-field-royal'>
                        <label>Email Addres *</label>
                        <input type='text' className='input-royal' value={values.email} onChange={handleContact('email')}/>
                    </div>
                    <div className='form-field-royal'>
                        <label>Phone Number</label>
                            <PhoneInput
                            value={phoneNumber}
                            placeholder='+1 619 754 7257'
                            className='input-royal'
                            onChange={handleChangeNumber}/>
                    </div>
                    <div className='form-field-royal'>
                        <label>Comments</label>
                        <input type="text" className='input-royal' value={values.comments} onChange={handleContact('comments')} placeholder={'Special request'}/>
                    </div>
                    <label className='label-group'>
                        Flight Information
                    </label>
                    <div className='clear-fix'>
                        <div className='form-field-2 clear-fix'>
                            <label>Flight Number</label>
                            <input type='text' value={values.flightNumber} onChange={handleContact('flightNumber')}/>
                        </div>
                        <div className='form-field-2 clear-fix'>
                            <label>Airline</label>
                            <input type='text' value={values.airline} onChange={handleContact('airline')}/>
                        </div>
                    </div>
                    <div className='form-field-royal'>
                        <label>Order Notes</label>
                        <input type="text" className='input-royal' value={values.notes} onChange={handleContact('notes')}/>
                    </div>
                </div>
            </div>
            <div className='col-md-6 col-lg-6'>
            <div className="row">
                <center><h4>Choose Payment</h4></center>
                <Stack {...getRootProps()}>
                    <center><Text>The selected method is: {value}</Text></center>
                    <HStack>
                        {options.map((option) => {
                        return (
                            <CustomRadio
                            key={option.name}
                            image={option.image}
                            payname={option.name}
                            {...getRadioProps({ value: option.name })}
                            />
                        )
                        })}
                    </HStack>
                </Stack>
            </div>
            </div>
            <div style={{paddingBottom:'50px', paddingTop: '50px'}} className='button-navigate'>
                <Button
                    mr={4}
                    className='navigation-button button-booking'
                    border='1px'
                    textTransform='uppercase'
                    backgroundColor='#660C49'
                    fontSize='14px'
                    borderRadius='15px'
                    color='#ffffff'
                    leftIcon={<ChevronLeftIcon w={8} h={8}/>}
                    colorScheme='teal'
                    variant='outline'
                    _hover={{ bg: '#FFFFFF', color:'#660C49' }}
                    size="sm"
                    onClick={() => prevSteps(2)}>
                    Choose a Vehicle
                </Button>
                <Button
                    mr={4}
                    className='navigation-button button-booking'
                    border='1px'
                    textTransform='uppercase'
                    backgroundColor='#660C49'
                    fontSize='14px'
                    borderRadius='15px'
                    color='#ffffff'
                    rightIcon={<ChevronRightIcon w={8} h={8}/>}
                    colorScheme='teal'
                    variant='outline'
                    _hover={{ bg: '#FFFFFF', color:'#660C49' }}
                    size="sm"
                    onClick={handleNextStep}>
                    Booking Summary
                </Button>
            </div>
        </div>
    </div>
    )

}


/*




*/