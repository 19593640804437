import React,{ useState, useEffect, useRef } from 'react';
import {
    Icon,
    Tooltip,
    Select,
    Button,
    useToast,
    Skeleton,
    Box,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    useDisclosure,
    useOutsideClick } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { MdPeopleOutline, MdOutlineLocationOn } from "react-icons/md";
import {
    useJsApiLoader,
    Autocomplete
} from '@react-google-maps/api';
import data from '../../data/data.json';
import priceRules from '../../data/priceRules.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveFormRide } from '../../app/rootSlice';
import { addCars, clearCars } from '../../app/cartSlice';

//const puntoMedio = { lat: 20.796117, lng: -86.955479 }
const puntoMedio = { lat: 20.918192, lng: -86.9770423 }
const libraries = ['places'];

function subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
}

export const Maps = () => {

    const formRide = useSelector((state) => state.root.formRide);

    const dispatch = useDispatch();
    const history = useNavigate();


    // Fechas de origen
    const fecha = new Date();
    fecha.setDate(fecha.getDate() + 2);
    const [selectedDay, setSelectedDay] = useState(fecha);
    const [inputValue, setInputValue] = useState(format(fecha, 'MM-dd-y'));
    const currentMonth = new Date();
    const [defaultMonth, setDefaultMonth] = useState(subtractMonths(currentMonth, 0));

    // Fechas de partida
    const [defaultMonthReturn, setDefaultMonthReturn] = useState(defaultMonth);
    const fechaReturn = new Date();
    fechaReturn.setDate(fechaReturn.getDate() + 2);
    const [selectedDayReturn, setSelectedDayReturn] = useState(fechaReturn);
    const [inputValueReturn, setInputValueReturn] = useState(format(fechaReturn, 'MM-dd-y'));

    //Nuevas fechas
    const [month, setMonth] = useState(defaultMonth);
    const [returnMonth, setReturnMonth] = useState(new Date());


    const [defaultValues, setDefaultValues] = useState({
        pickupDate: format(fecha, 'MM-dd-y'),
        pickupTime: '12:00',
        pickupLocation: '',
        pickoffLocation: '',
        tranferType: 'One Way',
        noAdults: 2,
        noChildrens: 0,
        totalDistance: '',
        totalTime: '',
        pickupDateReturn: '',
        pickupTimeReturn: '12:00'
    });
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyA5qtHzEi3rjG9_ZlK3l7UN62lCCENwiEY',
        libraries
    })
    const [cars, setCars] = useState([]);
    const rulesPrice = priceRules;
    // eslint-disable-next-line
    const [map, setMap] = useState(/** @type google.maps.Map */ (null));
    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef();
    const destinationRef = useRef();
    const toast = useToast();
    const toastIdRef = useRef();
    //Fechas de Date Picker
    const { onOpen, onClose, isOpen } = useDisclosure();
    const inputRef = useRef();
    const calendarRef = useRef();
    const inputRefReturn = useRef();
    const calendarRefReturn = useRef();
    const [open, setOpen] = useState(false);
    const [selectHours, setSelectHours] = useState([]);

    const bounds = {
        north: puntoMedio.lat + 0.6,
        south: puntoMedio.lat - 0.6,
        east: puntoMedio.lng + 0.6,
        west: puntoMedio.lng - 0.6,
    };

    const options = {
        strictBounds: true,
    };

    const restrictions = {
        country: 'MX',
    };

    const getGeofence = (origin) => {
        let id = 0;
        var inside = false;
        var x = origin[0], y = origin[1];
        for(var i = 0; i < data.length -1; i++){
            id = data[i].id;
            var geofenza = data[i].coordenadas;
            for( var j = 0, l = geofenza.length -1; j < geofenza.length; l = j++){
                var xj = geofenza[j][0], yj = geofenza[j][1];
                var xl = geofenza[l][0], yl = geofenza[l][1];

                var intersect = ((yj > y) !== (yl > y))
                    && (x < (xl - xj) * (y - yj) / (yl - yj) + xj);

                if(intersect) inside = !inside;

            }

            if(inside){
                break;
            }

        }
        console.log("id =>>>>>>>>>>>>>><", id);
        return id;
    }

    async function calculateRoute() {

        if (originRef.current.value === '' || destinationRef.current.value === '') {
          return;
        }
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
          origin: originRef.current.value,
          destination: destinationRef.current.value,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        })

        handleFormRideData(originRef.current.value,'pickupLocation');
        handleFormRideData(destinationRef.current.value, 'pickoffLocation');
        handleFormRideData(results.routes[0].legs[0].distance.text,'totalDistance');
        handleFormRideData(results.routes[0].legs[0].duration.text,'totalTime');

        var origen = [];
        origen.push(results.routes[0].legs[0].start_location.lat());
        origen.push(results.routes[0].legs[0].start_location.lng());
        var destino = [];
        destino.push(results.routes[0].legs[0].end_location.lat());
        destino.push(results.routes[0].legs[0].end_location.lng());
        const idOrigen = getGeofence(origen);
        const idDestino = getGeofence(destino);
        const prices = rulesPrice;
        const price = prices.filter(item => item.idGeofenceOrigen === idOrigen);
        var finalPrice = [];
        let inside = false;
        for(var j = 0; j < price.length; j ++){
            let destinos = price[j].idGeofenceDestino;
            for(var x = 0; x < destinos.length; x ++){
                if(destinos[x] === idDestino){
                    inside = true;
                    var precioCar = price[j];
                    finalPrice.push(precioCar)
                }
            }
        }

        if(inside){
            setCars(finalPrice);
        }else{
            return 11;
        }

    }

    async function reCalculateRoute(pickup = '', pickoff = '') {

        if (pickup === '' || pickoff === '') {
          return;
        }
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
          origin: pickup,
          destination: pickoff,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        })

        handleFormRideData(pickup,'pickupLocation');
        handleFormRideData(pickoff, 'pickoffLocation');
        handleFormRideData(results.routes[0].legs[0].distance.text,'totalDistance');
        handleFormRideData(results.routes[0].legs[0].duration.text,'totalTime');

        var origen = [];
        origen.push(results.routes[0].legs[0].start_location.lat());
        origen.push(results.routes[0].legs[0].start_location.lng());
        var destino = [];
        destino.push(results.routes[0].legs[0].end_location.lat());
        destino.push(results.routes[0].legs[0].end_location.lng());
        const idOrigen = getGeofence(origen);
        const idDestino = getGeofence(destino);
        const prices = rulesPrice;
        const price = prices.filter(item => item.idGeofenceOrigen === idOrigen);

        var finalPrice = [];
        let inside = false;
        for(var j = 0; j < price.length; j ++){
            let destinos = price[j].idGeofenceDestino;
            for(var x = 0; x < destinos.length; x ++){
                if(destinos[x] === idDestino){
                    inside = true;
                    var precioCar = price[j];
                    finalPrice.push(precioCar)
                }
            }
        }

        if(inside){
            originRef.current.value = pickup;
            destinationRef.current.value = pickoff;
            setCars(finalPrice);
        }

    }

    const handleSteps = () => {
        let isValid = true;
        const type = defaultValues.tranferType;
        const pickupLocation = defaultValues.pickupLocation;
        const pickoffLocation = defaultValues.pickoffLocation;
        const tranferType = defaultValues.tranferType;
        const pickupTime = defaultValues.pickupTime;
        const pickupTimeReturn = defaultValues.pickupTimeReturn;
        const adults = defaultValues.noAdults;

        if(inputValue === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'The pick-up date and time must be a valid date.',
                    position: 'top',
                    duration: 6000,
                    isClosable: true,
                }
            );
        }

        if(type === 'Round Trip'){
            if(inputValueReturn === '') {
                isValid = false;
                toastIdRef.current = toast(
                    {
                        status: "error",
                        title: 'Error',
                        description: 'The pick-up date and time to return must be a valid date.',
                        position: 'top',
                        duration: 6000,
                        isClosable: true,
                    }
                );
            }

            if(pickupTimeReturn === ''){
                isValid = false;
            }
        }

        if(pickupLocation === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid pickup location.',
                    position: 'top',
                    duration: 6000,
                    isClosable: true,
                }
            );
        }

        if(pickoffLocation === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid drop-off location.',
                    position: 'top',
                    duration: 6000,
                    isClosable: true,
                }
            );
        }

        if(tranferType === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Select a transfer type.',
                    position: 'top',
                    duration: 6000,
                    isClosable: true,
                }
            );
        }

        if(pickupTime === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid time.',
                    position: 'top',
                    duration: 6000,
                    isClosable: true,
                }
            );
        }

        if(Number(adults) === 0){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid passenger.',
                    position: 'top',
                    duration: 6000,
                    isClosable: true,
                }
            );
        }

        if(isValid){

            var roundType = defaultValues.tranferType;
            const pasajeros = Number(defaultValues.noAdults) + Number(defaultValues.noChildrens);
            var totalV = Math.ceil(pasajeros / 8);
            var totalS = Math.ceil(pasajeros / 5);

            const vehicles = [...cars];

            const shoppingList = vehicles.map(
                (item) => {
                    const selected = false;
                    const passengers = pasajeros;
                    let Total = 0;
                    let Vehiculos = 0;
                    let PrecioFinal = 0;

                    if(item.idVehiculo === 1){
                        Total = totalV * item.Precio;
                        Vehiculos = totalV;
                        PrecioFinal = Total;
                    }
                    if(item.idVehiculo === 2){
                        Total = totalS * item.Precio;
                        Vehiculos = totalS;
                        PrecioFinal = Total;
                    }

                    return {
                        ...item,
                        selected: selected,
                        passengers: passengers,
                        vehicles: Vehiculos,
                        precioFinal: PrecioFinal,
                    };
                }
             );

            let newVehicles = [];

            if(roundType === 'Round Trip'){
                newVehicles = shoppingList.map(
                    (item) => {
                        let Total = 0;
                        let Vehiculos = 0;
                        let precio = 0;

                        if(item.idVehiculo === 1){
                            Total = 2 * item.precioFinal;
                            Vehiculos = totalV;
                            precio = Total;
                        }
                        if(item.idVehiculo === 2){
                            Total = 2 * item.precioFinal;
                            Vehiculos = totalS;
                            precio = Total;
                        }
                        return {
                            ...item,
                            vehicles: Vehiculos,
                            precioFinal: precio,
                        };
                    }
                )

            }

            if(roundType === 'Round Trip'){
                dispatch(addCars(newVehicles));
            }else{
                dispatch(addCars(shoppingList));
            }
            dispatch(saveFormRide(defaultValues));
            history("/choose-vehicle");
        }

    }

    const validateRoute = () => {
        const pickUp = formRide.pickupLocation;
        const pickOff = formRide.pickoffLocation;
        if(pickUp !== '' && pickOff !== ''){
            reCalculateRoute(pickUp, pickOff);
            setDefaultValues(formRide);
        }
    }

    const handleValidate = (e) => {
        const esValido = e.target.validity.valid;
        const value  = e.target.value;
        if(esValido){
            handleFormRideData(value,'noAdults');
        }else{
            handleFormRideData('', 'noAdults');
        }
    }

    const handleValidateChildrens = (e) => {
        const esValido = e.target.validity.valid;
        const value  = e.target.value;
        if(esValido){
            handleFormRideData(value,'noChildrens');
        }else{
            handleFormRideData('', 'noChildrens');
        }
    }

    const handleOpen = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSelectDate = (date) => {
        setSelectedDay(date);
        if (date) {
            setInputValue(format(date, 'MM-dd-y'));
            handleFormRideData(format(date, 'MM-dd-y'),'pickupDate');
            handleFormRideData(format(date, 'MM-dd-y'), 'pickupDateReturn');
            setSelectedDayReturn(date);
            setInputValueReturn(format(date, 'MM-dd-y'));
            var newDate = new Date(date.getFullYear(),date.getMonth());
            setDefaultMonthReturn(newDate);
            onClose();
        } else {
            setInputValue('');
        }
    }

    const handleSelectReturnDate = (date) => {
        setSelectedDayReturn(date);
        if (date) {
            setInputValueReturn(format(date, 'MM-dd-y'));
            handleFormRideData(format(date, 'MM-dd-y'), 'pickupDateReturn');
            setOpen(false);
        } else {
            setInputValueReturn('');
        }
    }

    const handleChangeMonth = (date) => {
        setMonth(date);
        setReturnMonth(date);
    }

    const handleChangeMonthReturn = (date) => {
        setReturnMonth(date)
    }

    const handleFormRide = input => e => {

        const { value } = e.target;

        setDefaultValues(prevState => ({
            ...prevState,
            [input]: value
        }));

    }


    const handleFormRideData = (value, input) => {

        setDefaultValues(prevState => ({
            ...prevState,
            [input]: value
        }));

    }

    useOutsideClick({
        ref: inputRef,
        handler: (event) => {
            if (
                (event.target.contains(calendarRef.current) &&
                event.target !== inputRef.current) || (event.target.contains(calendarRefReturn.current) &&
                event.target !== inputRefReturn.current)
            ){
            onClose();
            handleClose();
          }
        }
    });

    const createHour = () => {
        var nextMinute,
            nextHour

        let getTime = (hour, minute, step) => {
            nextMinute = minute + step > 59 ? 60 - (minute + step) : minute + step;
            nextHour = nextMinute > minute ? hour : hour + 1 > 23 ? 0 : hour + 1;
            return [nextHour, nextMinute];
        };

        let padTime = (time) => `${`${time[0]}`.padStart(2, '0')}:${`${time[1]}`.padEnd(2, '0')}`;

        let makeSchedule = (currentTime, endTime, step) => {
            let nextTime = getTime(currentTime[0], currentTime[1], step);
            return nextTime.join(':') !== endTime.join(':')
                ? `${padTime(currentTime)}-${makeSchedule(nextTime, endTime, step)}`
                : `${padTime(currentTime)}-${padTime(nextTime)}`;
        };

        const scheduleList = makeSchedule([0, 0], [23, 0], 30).split('-');
        setSelectHours(scheduleList);

    }

    useEffect(() => {

    },[selectedDay])

    useEffect(() => {
        createHour();
        dispatch(clearCars());
        validateRoute();
        window.scroll({
            top: 5,
            behavior: "smooth"
        });

        if(formRide.pickupDate !== ''){
            setInputValue(formRide.pickupDate);
            const ranges = formRide.pickupDate.split('-');
            const month = parseInt(ranges[0]);
            const defaultSelect = new Date(ranges[2], month-1, ranges[1]);
            setSelectedDay(defaultSelect);
            setMonth(defaultSelect);
        }
        if(formRide.pickupDateReturn !== ''){
            setInputValueReturn(formRide.pickupDateReturn);
            const ranges = formRide.pickupDateReturn.split('-');
            const month = parseInt(ranges[0]);
            const defaultSelect = new Date(ranges[2], month-1, ranges[1]);
            setSelectedDayReturn(defaultSelect);
            setReturnMonth(defaultSelect);
        }

    // eslint-disable-next-line
    },[])


    if(!isLoaded){
        return(
            <>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md-12 ml-2 mr-5'>
                            <div className='card-royal'>
                                <label className='label-group'>
                                </label>
                                <div className='clear-fix'>
                                    <div className='form-field-2' style={{height: '98px'}}>
                                        <Skeleton
                                            height='98px'
                                            isLoaded={isLoaded}
                                            bg='green.200'
                                            color='white'
                                            fadeDuration={1}
                                        ></Skeleton>
                                    </div>
                                    <div className='form-field-2' style={{height: '98px'}}>
                                        <Skeleton
                                            height='98px'
                                            isLoaded={isLoaded}
                                            bg='green.200'
                                            color='white'
                                            fadeDuration={1}
                                        ></Skeleton>
                                    </div>
                                </div>
                                <div className='form-field-royal'>
                                    <Skeleton
                                        height='98px'
                                        isLoaded={isLoaded}
                                        bg='green.200'
                                        color='white'
                                        fadeDuration={1}
                                    ></Skeleton>
                                </div>
                                <div className='form-field-royal'>
                                    <Skeleton
                                        height='98px'
                                        isLoaded={isLoaded}
                                        bg='green.200'
                                        color='white'
                                        fadeDuration={1}
                                    ></Skeleton>
                                </div>
                                <div className='form-field-royal'>
                                    <Skeleton
                                        height='98px'
                                        isLoaded={isLoaded}
                                        bg='green.200'
                                        color='white'
                                        fadeDuration={1}
                                    ></Skeleton>
                                </div>
                                <label className='label-group'></label>
                                <div className='clear-fix'>
                                    <div className='form-field-2' style={{height: '98px'}}>
                                        <Skeleton
                                            height='98px'
                                            isLoaded={isLoaded}
                                            bg='green.200'
                                            color='white'
                                            fadeDuration={1}
                                        ></Skeleton>
                                    </div>
                                    <div className='form-field-2' style={{height: '98px'}}>
                                        <Skeleton
                                            height='98px'
                                            isLoaded={isLoaded}
                                            bg='green.200'
                                            color='white'
                                            fadeDuration={1}
                                        ></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return(
        <>
        <div className='row' style={{width:'98% !important'}}>
            <div className='card-royal'>
                <label className='label-group royal-border-booking'>
                    Ride Details
                </label>
                <div className='clear-fix'>
                    <div className='form-field-royal'>
                        <label>Transfer type
                            <Tooltip label="Transfer type of the journey" aria-label='A tooltip'><InfoIcon/></Tooltip>
                        </label>
                        <span className='icons-left'>
                            <Icon as={MdOutlineLocationOn} className='icons-left'/>
                        </span>
                        <Select value={defaultValues.tranferType} onChange={handleFormRide('tranferType')} variant='unstyled' className='select-royal'>
                            <option value='One Way'>One Way</option>
                            <option value='Round Trip'>Round Trip</option>
                        </Select>
                    </div>
                </div>
                        <div className='form-field-royal'>
                            <label>PICKUP LOCATION
                            <Tooltip label="The address where your journey will start" aria-label='A tooltip'><InfoIcon/></Tooltip>
                            </label>
                            <span className='icons-left'>
                            <Icon as={MdOutlineLocationOn} className='icons-left'/>
                            </span>
                            <Autocomplete
                                restrictions={restrictions}
                                options={options}
                                placeholder="Enter your location"
                                bounds={bounds}
                                >
                                <input
                                    type="text"
                                    className='input-royal'
                                    ref={originRef}
                                    onBlur={() => calculateRoute()}
                                />
                            </Autocomplete>
                        </div>
                        <div className='form-field-royal'>
                            <label>DROP-OFF LOCATION
                            <Tooltip label="The address where your journey will end" aria-label='A tooltip'><InfoIcon/></Tooltip>
                            </label>
                            <span className='icons-left'>
                            <Icon as={MdOutlineLocationOn} className='icons-left'/>
                            </span>
                            <Autocomplete
                                restrictions={restrictions}
                                options={options}
                                placeholder="Enter your location"
                                bounds={bounds}>
                                <input
                                    type="text"
                                    className='input-royal'
                                    id="destination"
                                    ref={destinationRef}
                                    onBlur={() => calculateRoute()}
                                />
                            </Autocomplete>
                        </div>
                        <Popover
                            isOpen={isOpen}
                            autoFocus={false}
                            onClose={onClose}
                            onOpen={onOpen}
                            placement="bottom-start">
                            <PopoverTrigger>
                            <div className='form-field-2' style={{height: '98px'}}>
                                <label>Pickup Date
                                    <Tooltip label="The date when your journey will start " aria-label='A tooltip'><InfoIcon/></Tooltip>
                                </label>
                                <label className='input-royal'>{inputValue}</label>
                            </div>
                            </PopoverTrigger>
                            <PopoverContent onClick={(e) => e.stopPropagation()}>
                                <PopoverBody p={2}>
                                <Box maxWidth="500px" bg="transparent" ref={calendarRef}>
                                <DayPicker
                                    mode="single"
                                    fromMonth={defaultMonth}
                                    required
                                    selected={selectedDay}
                                    onSelect={handleSelectDate}
                                    disabled={{ before: fecha }}
                                    month={month}
                                    onMonthChange={handleChangeMonth}
                                    modifiersClassNames={{
                                    selected: 'my-selected',
                                    today: 'my-today'
                                    }}
                                    />
                                </Box>

                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                        <div className='form-field-2' style={{height: '98px'}}>
                            <label>Pickup Time
                                <Tooltip label="The time when your journey will start" aria-label='A tooltip'><InfoIcon/></Tooltip>
                            </label>
                            <Select variant='unstyled' className='select-royal' placeholder='Time'
                                value={defaultValues.pickupTime} onChange={handleFormRide('pickupTime')}>
                                {
                                selectHours?.map((item) => {
                                    return(
                                        <option value={item} key={item}>{item}</option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        {
                            (defaultValues.tranferType === 'Round Trip')
                                ?
                                <>
                                    <label className='label-group'>
                                        Ride Return
                                    </label>
                                    <div className='clear-fix'>
                                        <Popover
                                            isOpen={open}
                                            autoFocus={false}
                                            onClose={handleOpen}
                                            onOpen={handleOpen}
                                            placement="bottom-start"
                                            >
                                            <PopoverTrigger>
                                                <div className='form-field-2' style={{height: '98px'}}>
                                                <label>Pickup Date
                                                    <Tooltip label="The address where your journey will start" aria-label='A tooltip'><InfoIcon/></Tooltip>
                                                </label>
                                                    <label className='input-royal'>{inputValueReturn}</label>
                                                </div>
                                            </PopoverTrigger>
                                            <PopoverContent onClick={(e) => e.stopPropagation()}>
                                                <PopoverBody p={2}>
                                                <Box maxWidth="500px" bg="transparent" ref={calendarRefReturn}>
                                                    <DayPicker
                                                        mode="single"
                                                        month={returnMonth}
                                                        onMonthChange={handleChangeMonthReturn}
                                                        selected={selectedDayReturn}
                                                        fromMonth={defaultMonth}
                                                        onSelect={handleSelectReturnDate}
                                                        disabled={{ before: selectedDay }}
                                                        modifiersClassNames={{
                                                            selected: 'my-selected',
                                                            today: 'my-today'
                                                        }}
                                                    />
                                                </Box>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        <div className='form-field-2' style={{height: '98px'}}>
                                        <label>Pickup Time
                                            <Tooltip label="The address where your journey will start" aria-label='A tooltip'><InfoIcon/></Tooltip>
                                        </label>
                                        <span className='icons-left'>
                                        </span>
                                        <Select variant='unstyled' className='select-royal' placeholder='Time'
                                            value={defaultValues.pickupTimeReturn} onChange={handleFormRide('pickupTimeReturn')}>
                                            {
                                                    selectHours?.map((item) => {
                                                        return(
                                                            <option value={item} key={item}>{item}</option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                </>
                        }

                        <label className='label-group'>
                            Number of passengers
                        </label>
                        <div className='clear-fix'>
                            <div className='form-field-2 clear-fix border-bottom-left'>
                                <label>Adults
                                    <Tooltip label="The number of adults" aria-label='A tooltip'><InfoIcon/></Tooltip>
                                </label>
                                <span className='icons-left'>
                                    <Icon as={MdPeopleOutline} className='icons-left'/>
                                </span>
                                <Select value={defaultValues.noAdults} onChange={handleFormRide('noAdults')} variant='unstyled' className='select-royal'>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                    <option value='8'>8</option>
                                    <option value='9'>9</option>
                                    <option value='10'>10</option>
                                    <option value='11'>11</option>
                                    <option value='12'>12</option>
                                    <option value='13'>13</option>
                                    <option value='14'>14</option>
                                    <option value='15'>15</option>
                                    <option value='16'>16</option>
                                </Select>
                            </div>
                            <div className='form-field-2 clear-fix border-bottom-right'>
                                <label>Children
                                    <Tooltip label="The number of children" aria-label='A tooltip'><InfoIcon/></Tooltip>
                                </label>
                                <span className='icons-left'>
                                    <Icon as={MdPeopleOutline} className='icons-left'/>
                                </span>
                                <Select value={defaultValues.noChildrens} onChange={handleFormRide('noChildrens')} variant='unstyled' className='select-royal'>
                                    <option value='0'>0</option>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                    <option value='8'>8</option>
                                    <option value='9'>9</option>
                                    <option value='10'>10</option>
                                    <option value='11'>11</option>
                                    <option value='12'>12</option>
                                    <option value='13'>13</option>
                                    <option value='14'>14</option>
                                    <option value='15'>15</option>
                                    <option value='16'>16</option>
                                </Select>
                            </div>
                        </div>
            </div>
            <div className='button-maps' style={{textAlign:'center'}}>
                <Button
                    mr={4}
                    className='navigation-button button-booking padding-button'
                    textTransform='uppercase'
                    backgroundColor='#660C49'
                    fontSize='20px'
                    borderRadius='15px'
                    color='#ffffff'
                    _hover={{ bg: '#FFFFFF', color:'#660C49' }}
                    size="sm"
                    onClick={handleSteps}>
                    Book Now
                </Button>
            </div>
        </div>
        </>
    )
}
