import { useEffect, useRef, useState } from 'react';
import { Button, Box, Image, useToast, useRadio, useRadioGroup, HStack, Stack, chakra, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { saveFormContact } from '../../app/rootSlice';
import { useNavigate } from 'react-router-dom';
import { Banner } from '../../ComponentsV1/Carousel/Banner';


export const FormContact = () => {

    function CustomRadio(props) {

        const { image, payname,...radioProps } = props
        const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } = useRadio(radioProps)

        return (
            <chakra.label {...htmlProps} cursor='pointer'>
              <input {...getInputProps({})} hidden />
              <Box
                {...getCheckboxProps()}
                bg={state.isChecked ? '#f7e8ed' : 'transparent'}
                borderWidth='1px'
              >
                <Image src={image} {...getLabelProps()} />
              </Box>
            </chakra.label>
          )
    }

    const dispatch = useDispatch();
    const history = useNavigate();
    const formContact = useSelector((state) => state.root.formContact);
    const formCar = useSelector((state) => state.root.formVehicles);
    const formRide = useSelector((state) => state.root.formRide);
    const ref = useRef(null);
    const toast = useToast()
    const toastIdRef = useRef();
    const [phoneNumber, setValue] = useState();
    const [valuesDefault, setValuesDefault] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        comments: '',
        flightNumber: '',
        airline: '',
        notes: '',
        paymentMethod: "Cash On Arrival"
    });

    const options = [
        {
            id: 1,
            name:'Cash On Arrival',
            image: 'https://royalpickupsrc.s3.amazonaws.com/payments/casharrival.webp'
        },
        {
            id: 2,
            name: 'Pay using Credit/Debit Card',
            image: 'https://royalpickupsrc.s3.amazonaws.com/payments/paydebitcard.webp'
        },
        {
            id: 3,
            name: 'Paypal',
            image: 'https://royalpickupsrc.s3.amazonaws.com/payments/Paypal.webp'
        }
    ]

    {/*
        {
            id: 4,
            name: 'Online Payment',
            image: 'https://royalpickupsrc.s3.amazonaws.com/payments/paydebitcard.webp'
        }
    */}

    const handleForm = (input, value) => {
        setValuesDefault(prevState => ({
            ...prevState,
            [input]: value
        }))
    }

    const handleChange = (value) => {
        handleForm('paymentMethod', value)
    }

    const handleChangeNumber = (value) => {
        setValue(value);
        handleForm('phone', value);
    }

    const { value, getRadioProps, getRootProps } = useRadioGroup({
        defaultValue: formContact.paymentMethod,
        onChange: handleChange,
    })

    const handleNextStep = () => {

        let isValid = true;

        const firstName = valuesDefault.firstName;
        const lastName = valuesDefault.lastName;
        const email = valuesDefault.email;
        const phone = valuesDefault.phone;

        if(firstName === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid First Name.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(lastName === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid Last Name.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(email === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid email.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(phone === ''){
            isValid = false;
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Enter a valid Phone.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        if(isValid){
            dispatch(saveFormContact(valuesDefault));
            history('/summary');
        }

    }

    const returnPage = (url) => {
        history(url);
    }

    const validateData = () => {
        if(formContact.firstName !== ""){
            setValuesDefault(formContact);
            setValue(formContact.phone);
        }
    }

    useEffect(() => {

        const exist = formCar.hasOwnProperty("selected");
        if(!exist){
            if(formRide.pickupLocation === ""){
                history("/ride-details");
            }else{
                history("/choose-vehicle");
            }
        }

        validateData();
        window.scroll({
            top: 5,
            behavior: "smooth"
        });
    },[])

    return(
        <>
            <Banner/>
            <div className='container mt-1'>
                <div className='row'>
                    <div ref={ref} id="stepper1" className="bs-stepper desktop-version">
                        <div className="bs-stepper-header">
                            <div className="step" data-target="#test-l-1">
                                <button className="step-trigger" onClick={() => returnPage("/ride-details")}>
                                    <span className="bs-stepper-circle active-step">1</span>
                                    <span className="bs-stepper-label">Enter Ride Details</span>
                                </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#test-l-2">
                                <button className="step-trigger" onClick={() => returnPage("/choose-vehicle")}>
                                    <span className="bs-stepper-circle active-step">2</span>
                                    <span className="bs-stepper-label">Choose a Vehicle</span>
                                </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#test-l-3">
                                <button className="step-trigger">
                                    <span className="bs-stepper-circle active-step">3</span>
                                    <span className="bs-stepper-label">Enter Contact Details</span>
                                </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#test-l-3">
                                <button className="step-trigger">
                                    <span className="bs-stepper-circle">4</span>
                                    <span className="bs-stepper-label">Checkout</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                        <div className='card-royal'>
                            <label className='label-group'>
                                Contact details
                            </label>
                            <div className='clear-fix'>
                                <div className='form-field-2'>
                                    <label>First Name *</label>
                                    <input type="text" value={valuesDefault.firstName} className='input-royal' onChange={(e) => handleForm('firstName', e.target.value)}/>
                                </div>
                                <div className='form-field-2'>
                                    <label>Last Name *</label>
                                    <input type="text" value={valuesDefault.lastName} className='input-royal' onChange={(e) => handleForm('lastName', e.target.value)}/>
                                </div>
                            </div>
                            <div className='form-field-royal'>
                                <label>Email Addres *</label>
                                <input type='text' value={valuesDefault.email} className='input-royal' onChange={(e) => handleForm('email', e.target.value)}/>
                            </div>
                            <div className='form-field-royal'>
                                <label>Phone Number</label>
                                    <PhoneInput
                                    value={phoneNumber}
                                    placeholder='+1 619 754 7257'
                                    className='input-royal'
                                    onChange={handleChangeNumber}/>
                            </div>
                            <div className='form-field-royal'>
                                <label>Comments</label>
                                <input type="text" value={valuesDefault.comments} className='input-royal' placeholder={'Special request'} onChange={(e) => handleForm('comments', e.target.value)}/>
                            </div>
                            <label className='label-group'>
                                Flight Information
                            </label>
                            <div className='clear-fix'>
                                <div className='form-field-2 clear-fix'>
                                    <label>Flight Number</label>
                                    <input type='text' value={valuesDefault.flightNumber} onChange={(e) => handleForm('flightNumber', e.target.value)} />
                                </div>
                                <div className='form-field-2 clear-fix'>
                                    <label>Airline</label>
                                    <input type='text' value={valuesDefault.airline} onChange={(e) => handleForm('airline', e.target.value)}/>
                                </div>
                            </div>
                            <div className='form-field-royal'>
                                <label>Order Notes</label>
                                <input type="text" className='input-royal' value={valuesDefault.notes} onChange={(e) => handleForm('notes', e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-6'>
                    <div className="row">
                        <center><h4>Choose Payment</h4></center>
                        <Stack {...getRootProps()}>
                            <center><Text>The selected method is: <span style={{fontWeight:"bold"}}>{value}</span></Text></center>
                            <HStack>
                                {options.map((option) => {
                                return (
                                    <CustomRadio
                                    key={option.name}
                                    image={option.image}
                                    payname={option.name}
                                    {...getRadioProps({ value: option.name })}
                                    />
                                )
                                })}
                            </HStack>
                        </Stack>
                    </div>
                    </div>
                    <div style={{textAlign:'-webkit-right', paddingBottom:'50px', paddingTop: '50px'}}>
                        <Button
                            mr={4}
                            className='navigation-button button-booking'
                            border='1px'
                            textTransform='uppercase'
                            backgroundColor='#660C49'
                            fontSize='14px'
                            borderRadius='15px'
                            color='#ffffff'
                            rightIcon={<ChevronRightIcon w={8} h={8}/>}
                            colorScheme='teal'
                            variant='outline'
                            _hover={{ bg: '#FFFFFF', color:'#660C49' }}
                            size="sm"
                            onClick={handleNextStep}>
                            Booking Summary
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )

}
