import React from 'react';
import { Maps } from '../components/Maps';

const DestinationsPage = () => {

   const data = [{
      img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/cancun.webp',
      title: "Cancun",
      description: "Discovers white sand beaches light turquoise waters, crazy nights and cultural visits all in one city: Cancún"
   },{
         img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/Playa_del_carmen.webp',
         title: "Playa del Carmen",
         description: "Enjoy the charm of this town where luxury and entertainment meet."
   },{
         img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/tulum.webp',
         title: "Tulum",
         description: "Tulum is the host of unbelievable natural attractions that will amaze anyone."
   },{
         img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/puerto_morelos.webp',
         title: "Puerto Morelos",
         description: "Visit small fishing village that amazes travelers from being such a quiet place with picturesque streets."
   },{
         img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/puerto_aventuras.webp',
         title: "Puerto Aventuras",
         description: "Try all the diferent activities, Puerto Aventuras bring while being surrounded by crystalline waters, white fine sand and tropical vegetation."
   },{
         img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/akumal.webp',
         title: "Akumal",
         description: "Find unique landscapes in the middle of the Mayan jungle, cenotes and hotels."
   },{
         img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/playa_mujeres.webp',
         title: "Playa Mujeres",
         description: "Explore the beautiful beach of Isla Blanca or walk in the incredible Chacmuchuch lagoon."
   },{
         img: 'https://royalpickupsrc.s3.amazonaws.com/destinos/holbox.webp',
         title: "Holbox",
         description: "Reach the port of Chiquilá and set sail to the natural beauty of the Holbox Island within the nature reserve of Yum Balam."
   }]

  return (
      <>
      <div className='sections'>
         <div className='landing mb-4'>
            <div className='container'>
               <div className="row">
                  <div className="col-lg-4 col-md-4 col-12 order-sm-first order-last">
                     <Maps/>
                  </div>
                  <div className="col-lg-8 col-md-8 col-12 text-captions order-sm-first">
                     <h1 className='captions-royal'>PRIVATE TRANSFER</h1>
                     <p className='subtitle-captions'>Discover the most unforgettable places in the RIVIERA MAYA.</p>
                  </div>
               </div>
            </div>
         </div>
         <div className='container pb-4'>
            <h1 className='title-red'>NEED TRANSPORTATION TO CANCUN OR RIVIERA MAYA?</h1>
            <h2 className='text-center'>FIND YOUR DESTINY</h2>
            <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
               {
                  data.map((item, idx) => (
                     <div className="col" key={idx}>
                        <div className="card h-100 border-0">
                           <img src={item.img} className="card-img-top image-destinations" alt={item.title}/>
                           <div className="card-body">
                           <h2 className="card-title">{item.title}</h2>
                           <hr className='divider'></hr>
                           <p className="card-text">{item.description}</p>
                           </div>
                        </div>
                     </div>
                  ))
               }
            </div>
         </div>
      </div>
      </>
   );
};

export default DestinationsPage;
