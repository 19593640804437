import { Box, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearForms } from '../app/rootSlice';
import { clearExtras } from '../app/extraSlice';
import { clearCars } from '../app/cartSlice';
import { useEffect } from 'react';

export const Confirmation = () => {

  const formRide = useSelector((state) => state.root.formRide);
  const history = useNavigate();
  const dispatch = useDispatch();

  const returnHome = () => {
    dispatch(clearForms());
    dispatch(clearExtras());
    dispatch(clearCars());
    history("/");
  }

  useEffect(() => {
    return () => {
      dispatch(clearForms());
      dispatch(clearExtras());
      dispatch(clearCars());
    }
  },[])

    return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Your reservation was successfull
      </Heading>
      <Text color={'gray.500'}>
        Thank you for your reservation. We will be in contact with more details shortly.
      </Text>
        <button className='chbs-button chbs-button-style-2 selected-button' style={{margin:'50px'}} onClick={returnHome}>Return to home</button>
    </Box>
  );
}