import React, {  useEffect, useRef, useState } from 'react';
import {
    Textarea, Accordion, AccordionItem, AccordionButton,
    Box, AccordionIcon, AccordionPanel, useToast, Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    PayPalButtons,
    usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Banner } from '../../ComponentsV1/Carousel/Banner';

export const FormCheckout = () => {

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    const history = useNavigate();
    const formRide = useSelector((state) => state.root.formRide);
    const formContact = useSelector((state) => state.root.formContact);
    const formVehicle = useSelector((state) => state.root.formVehicles);
    const extras = useSelector((state) => state.extra.extras);
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const ref = useRef(null);
    const [code, setCode] = useState('');
    const [newTotal,setNewTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const toast = useToast();
    const toastIdRef = useRef();
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [Error, setError] = useState(false);
    const [orderID, setOrderID] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalExtra, setTotalExtra] = useState(0);

    const handleApplyCode = () => {
        if(code.toUpperCase().trim() === 'ROYAL20'){
            let newPrice = 0;
            let discount = 0;
            if(total >= 130){
                newPrice = Math.ceil((total * (100 - 20))/ 100);
                setNewTotal(newPrice);
                discount = total - newPrice;
                setDiscount(discount);
                toastIdRef.current = toast(
                    {
                        status: "success",
                        title: 'Success',
                        description: 'Coupon code applied successfully',
                        position: 'top',
                        duration: 2000,
                        isClosable: true,
                    }
                );
            }else{
                toastIdRef.current = toast(
                    {
                        status: "error",
                        title: 'Error',
                        description: 'Minimum amount of 130 USD to apply the code.',
                        position: 'top',
                        duration: 3000,
                        isClosable: true,
                    }
                );
            }
        }else{
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Not valid code.',
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                }
            );
        }
    }

    const handleKeyPress = e => {
        if(e.key === 'Enter'){
            handleApplyCode();
        }
    }

    const createData = () => {

        let data = {};
        let clientDetail = {};
        let services = {};
        let extrasService = extras;

        clientDetail.firstName = formContact.firstName;
        clientDetail.lastName = formContact.lastName;
        clientDetail.email = formContact.email;
        clientDetail.phone = formContact.phone;

        services.type = formRide.tranferType;
        services.name = formVehicle.nombreVehiculo;
        services.idVehicle = formVehicle.idVehiculo;
        services.quantity = 1;
        services.price = formVehicle.precioFinal;
        services.discount = discount;
        services.total = newTotal > 0 ? newTotal : total;
        services.subtotal = total;
        services.payment = formContact.paymentMethod;
        services.passenger = formVehicle.passengers;
        services.noAdults = formRide.noAdults;
        services.noChilds = formRide.noChildrens;

        data.extra = extrasService.length > 0 ? true : false;
        data.extras = extras;
        data.clientDetail = [clientDetail];
        data.services = [services];

        data.pickupLocation = formRide.pickupLocation;
        data.pickOffLocation = formRide.pickoffLocation;
        data.datePickup = formRide.pickupDate;
        data.timePickup = formRide.pickupTime;
        data.dateReturnPickup = formRide.pickupDateReturn;
        data.timeReturnPickup = formRide.pickupTimeReturn;

        data.pickupDateTime = formRide.pickupDate + ' - ' + formRide.pickupTime;
        data.returnDateTime = formRide.pickupDateReturn + ' - ' + formRide.pickupTimeReturn;
        data.flightNumber = formContact.flightNumber;
        data.airline = formContact.airline;
        data.ordernotes = formContact.notes;
        data.comments = formContact.comments;
        data.datecreated = formatDate(new Date());
        data.estado = 1;
        data.orderNumber = Math.floor(Math.random() * (1000 - 500 + 1) + 500);

        data.cc = '';
        data.emailSend = formContact.email;


        return data;
    }

    const handleSubmit = async() => {

        setShow(true);

        let data = createData();

        const response = await fetch('https://back.royalpickup.com/api/mail/sendmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        if(response.ok){
            const responseData = await response.json();

            if(responseData.error === false){
                handleSendEmail(data);
                setShow(false);
                history("/order-confirmed");
            }

        }

        if (!response.ok) {
            setShow(false);
            toastIdRef.current = toast(
                {
                    status: "error",
                    title: 'Error',
                    description: 'Order Failed, try again.',
                    position: 'top',
                    duration: 9000,
                    isClosable: true,
                }
            );
        }

        setShow(false);

    }

    {/*
        const handleSubmit = e => {

            setShow(true);

            let data = {};
            let clientDetail = {};
            let services = {};
            let extrasService = extras;

            clientDetail.firstName = formContact.firstName;
            clientDetail.lastName = formContact.lastName;
            clientDetail.email = formContact.email;
            clientDetail.phone = formContact.phone;

            services.type = formRide.tranferType;
            services.name = formVehicle.nombreVehiculo;
            services.idVehicle = formVehicle.idVehiculo;
            services.quantity = 1;
            services.price = formVehicle.precioFinal;
            services.discount = discount;
            services.total = newTotal > 0 ? newTotal : total;
            services.subtotal = total;
            services.payment = formContact.paymentMethod;
            services.passenger = formVehicle.passengers;
            services.noAdults = formRide.noAdults;
            services.noChilds = formRide.noChildrens;

            data.extra = extrasService.length > 0 ? true : false;
            data.extras = extras;
            data.clientDetail = [clientDetail];
            data.services = [services];

            data.pickupLocation = formRide.pickupLocation;
            data.pickOffLocation = formRide.pickoffLocation;
            data.datePickup = formRide.pickupDate;
            data.timePickup = formRide.pickupTime;
            data.dateReturnPickup = formRide.pickupDateReturn;
            data.timeReturnPickup = formRide.pickupTimeReturn;

            data.pickupDateTime = formRide.pickupDate + ' - ' + formRide.pickupTime;
            data.returnDateTime = formRide.pickupDateReturn + ' - ' + formRide.pickupTimeReturn;
            data.flightNumber = formContact.flightNumber;
            data.airline = formContact.airline;
            data.ordernotes = formContact.notes;
            data.comments = formContact.comments;
            data.datecreated = formatDate(new Date());
            data.estado = 1;

            data.cc = '';
            data.emailSend = formContact.email;

            console.log("data =>>>>>>>>>>>>>>>>", data);

            fetch('https://api.royalpickup.com/api/mail/sendmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response) => {
                response.json();
                handleSendEmail(data);
                dispatch(updatePayment(true));
                history("/order-confirmed");
            })
            .then((data) => {
                console.log("data =>>>>>>>>>>>>>>>>", data);
            })
            .catch((error) => {
                setShow(false);
                toastIdRef.current = toast(
                    {
                        status: "error",
                        title: 'Error',
                        description: 'Order Failed, try again.',
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                    }
                );
            });
        }
    */}

    const handleSendEmail = (data) => {
        data.emailSend = 'hagosto@royalpickup.com';
        data.cc = 'ricardo@bemoresocial.com.mx, andry.matoscaamal@gmail.com, ramoleo@hotmail.com';
        fetch('https://back.royalpickup.com/api/mail/sendmail', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then((response) => {
        })
        .then((json) => {
        })
        .catch((err) => {
        });
    }

    const handleCheckout = async () => {

        let dataCorreo = createData();

        let cartItems = [];
        let objectCar = {};

        objectCar["name"] = formRide.tranferType;
        objectCar["image"] = formVehicle.idVehiculo === 1 ? 'https://royalpickupsrc.s3.amazonaws.com/van.webp':'https://royalpickupsrc.s3.amazonaws.com/suburban.webp';
        objectCar["desc"] = formVehicle.nombreVehiculo;
        objectCar["price"] = formVehicle.precioFinal;
        objectCar["cartQuantity"] = 1;
        objectCar["id"] = formVehicle.id;

        cartItems.push(objectCar);

        if(extras.length > 0){
            for(var i = 0; i <= extras.length -1; i++){
                let object = {};
                object["name"] = extras[i].nombre;
                object["image"] = 'https://royalpickupsrc.s3.amazonaws.com/logo-royal.webp';
                object["desc"] = extras[i].descripcion;
                object["price"] = extras[i].precio;
                object["cartQuantity"] = extras[i].cantidad;
                object["id"] = extras[i].id;
                cartItems.push(object);
            }
        }

        const settings = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "cartItems": [
                        cartItems
                    ],
                    "emailData": dataCorreo,
                    "coupon": (code.toUpperCase().trim() === 'ROYAL20' && total >= 130) ? code.toUpperCase().trim() : "",
                }
            )
        }

        try {
            const fetchResponse = await fetch('https://back.royalpickup.com/api/stripe/create-checkout-session', settings);
            const data = await fetchResponse.json();
            if(data.url){
                window.location.href = data.url;
            }
            return data;
        } catch (e) {
            return e;
        }

    }

    // creates a paypal order
    const createOrder = (data, actions) => {
        const totalPaypal = newTotal > 0 ? newTotal : total;
        const descripcion = formRide.tranferType + " - " + formVehicle.nombreVehiculo;

        return actions.order.create({
            purchase_units: [
                {
                    description: descripcion,
                    amount: {
                        currency_code: "USD",
                        value: totalPaypal,
                    },
                },
            ],
        }).then((orderID) => {
                setOrderID(orderID);
                return orderID;
            });
    };

    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details;
            //Guardado o actualizado de la orden
            setSuccess(true);
        });
    };

    //capture likely error
    const onError = (data, actions) => {
        setError(true);
    };

    const returnPage = (url) => {
        history(url);
    }

    useEffect(() => {
        if (success) {
            setShow(true);

            let data = {};
            let clientDetail = {};
            let services = {};
            let extrasService = extras;

            let paypalID = formContact.paymentMethod === 'Paypal' ? formContact.paymentMethod + " ID: " + orderID : formContact.paymentMethod;

            clientDetail.firstName = formContact.firstName;
            clientDetail.lastName = formContact.lastName;
            clientDetail.email = formContact.email;
            clientDetail.phone = formContact.phone;

            services.type = formRide.tranferType;
            services.name = formVehicle.nombreVehiculo;
            services.idVehicle = formVehicle.idVehiculo;
            services.quantity = 1;
            services.price = formVehicle.precioFinal;
            services.discount = discount;
            services.total = newTotal > 0 ? newTotal : total;
            services.subtotal = total;
            services.payment = paypalID;
            services.passenger = formVehicle.passengers;
            services.noAdults = formRide.noAdults;
            services.noChilds = formRide.noChildrens;

            data.extra = extrasService.length > 0 ? true : false;
            data.extras = extras;
            data.clientDetail = [clientDetail];
            data.services = [services];

            data.pickupLocation = formRide.pickupLocation;
            data.pickOffLocation = formRide.pickoffLocation;
            data.datePickup = formRide.pickupDate;
            data.timePickup = formRide.pickupTime;
            data.dateReturnPickup = formRide.pickupDateReturn;
            data.timeReturnPickup = formRide.pickupTimeReturn;

            data.pickupDateTime = formRide.pickupDate + ' - ' + formRide.pickupTime;
            data.returnDateTime = formRide.pickupDateReturn + ' - ' + formRide.pickupTimeReturn;
            data.flightNumber = formContact.flightNumber;
            data.airline = formContact.airline;
            data.ordernotes = formContact.notes;
            data.comments = formContact.comments;
            data.datecreated = formatDate(new Date());
            data.estado = 1;
            data.orderNumber = Math.floor(Math.random() * (1000 - 500 + 1) + 500);

            data.cc = '';
            data.emailSend = formContact.email;


            fetch('https://back.royalpickup.com/api/mail/sendmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response) => {
                response.json();
                handleSendEmail(data);
                history("/order-confirmed");
            })
            .then((data) => {
            })
            .catch((error) => {
                setShow(false);
                toastIdRef.current = toast(
                    {
                        status: "error",
                        title: 'Error',
                        description: 'Order Failed, try again.',
                        position: 'top',
                        duration: 9000,
                        isClosable: true,
                    }
                );
            });

        }
    },[success]);

    useEffect(() => {

        const exist = formVehicle.hasOwnProperty("selected");

        if(!exist){
            if(formRide.pickupLocation === ""){
                history("/ride-details");
            }else{
                history("/choose-vehicle");
            }
        }

        const carPrice = formVehicle;
        const precio = carPrice['precioFinal'];
        const extraArray = extras;
        const totalExtra = extraArray.reduce((acum, item) => acum + (item.cantidad * item.precio), 0)
        setTotalExtra(totalExtra);
        const total = totalExtra + precio;
        setTotal(total);
        window.scroll({
            top: 5,
            behavior: "smooth"
        });
    // eslint-disable-next-line
    },[]);

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: 'USD',
            },
        });
    }, []);

    useEffect(() => {
        setNewTotal(total - discount);
    }, [discount]);

    return (
        <>
        <Banner/>
        <div className='container mt-1'>
            <div className='row'>
                <div ref={ref} id="stepper1" className="bs-stepper desktop-version">
                    <div className="bs-stepper-header">
                    <div className="step" data-target="#test-l-1">
                            <button className="step-trigger" onClick={() => returnPage("/ride-details")}>
                                <span className="bs-stepper-circle active-step">1</span>
                                <span className="bs-stepper-label">Enter Ride Details</span>
                            </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-2">
                            <button className="step-trigger" onClick={() => returnPage("/choose-vehicle")}>
                                <span className="bs-stepper-circle active-step">2</span>
                                <span className="bs-stepper-label">Choose a Vehicle</span>
                            </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-3">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle active-step" onClick={() => returnPage("/contact-details")}>3</span>
                                <span className="bs-stepper-label">Enter Contact Details</span>
                            </button>
                        </div>
                        <div className="line"></div>
                        <div className="step" data-target="#test-l-3">
                            <button className="step-trigger">
                                <span className="bs-stepper-circle active-step">4</span>
                                <span className="bs-stepper-label">Checkout</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='container mt-2'>
                    {
                        show ? <div className="loading"></div> : <></>
                    }
                    <div className='row'>
                        <div className='col-md-4 ml-2 mr-2'>
                            <div className='chbs-summary'>
                                <div className='summary-header'>
                                    <h4>Contact & Billing Info</h4>
                                </div>
                                <div className='summary-fields'>
                                    <div className='clear-fix'>
                                        <div className='field-left'>
                                            <div className='summary-field-name'>
                                            FirstName
                                            </div>
                                            <div className='summary-field-value'>
                                                {formContact.firstName}
                                            </div>
                                        </div>
                                        <div className='field-right'>
                                            <div className='summary-field-name'>
                                            Last Name
                                            </div>
                                            <div className='summary-field-value'>
                                                {formContact.lastName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                    E-MAIL ADDRESS
                                    </div>
                                    <div className='summary-field-value'>
                                    {formContact.email}
                                    </div>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                        Phone Number
                                    </div>
                                    <div className='summary-field-value'>
                                        {formContact.phone}
                                    </div>
                                </div>
                            </div>

                            <div className='chbs-summary'>
                                <div className='summary-header'>
                                    <h4>Payment Method</h4>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                        Your Choice
                                    </div>
                                    <div className='summary-field-value'>
                                        {
                                            formContact.paymentMethod
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='card-royal'>
                                <label className='label-group'>
                                    Vehicle Info
                                </label>
                                <div className='chbs-summary'>
                                    <div className='summary-fields'>

                                        {
                                            formVehicle.idVehiculo === 1 ?
                                                <img className='summary-info' src={'https://royalpickupsrc.s3.amazonaws.com/van.webp'} alt='vehicle'/>
                                                :
                                                <img className='summary-info' src={'https://royalpickupsrc.s3.amazonaws.com/suburban.webp'} alt='vehicle'/>
                                        }
                                        <div className='summary-field-name'>
                                            Vehicle
                                        </div>
                                        <div className='summary-field-value'>
                                            {formVehicle.vehicles} x {formVehicle.nombreVehiculo}
                                        </div>
                                    </div>
                                    <div className='summary-fields'>
                                        <div className='summary-field-name'>
                                        Passengers
                                        </div>
                                        <div className='summary-field-value'>
                                        { formVehicle.passengers } Passengers
                                        </div>
                                    </div>

                                    {
                                        extras ?
                                        <div className='summary-fields'>
                                            <div className='summary-field-name'>
                                                Extra Options
                                            </div>
                                            <div className='summary-field-value'>
                                            {
                                                extras?.map((item) => {
                                                    return(
                                                        <div key={item.id}>
                                                            {item.cantidad} x {item.nombre}
                                                            <br></br>
                                                        </div>
                                                    )
                                                })

                                            }
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4 ml-2 mr-2'>
                            <div className='chbs-summary'>
                                <div className='summary-header'>
                                    <h4>Ride Details</h4>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                    Transfer Type
                                    </div>
                                    <div className='summary-field-value'>
                                        {formRide.tranferType}
                                    </div>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                        Pickup Location
                                    </div>
                                    <div className='summary-field-value'>
                                        {formRide.pickupLocation}
                                    </div>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                        Drop Off Location
                                    </div>
                                    <div className='summary-field-value'>
                                        {formRide.pickoffLocation}
                                    </div>
                                </div>
                                <div className='summary-fields'>
                                    <div className='summary-field-name'>
                                        PICKUP DATE, TIME
                                    </div>
                                    <div className='summary-field-value'>
                                        {formRide.pickupDate}, {formRide.pickupTime}
                                    </div>
                                </div>
                            </div>
                            {
                                formRide.tranferType === 'Round Trip' ?
                                <>
                                <div className='chbs-summary'>
                                    <div className='summary-header'>
                                        <h4>Return Ride</h4>
                                    </div>
                                    <div className='summary-fields'>
                                        <div className='summary-field-name'>
                                            RETURN PICKUP LOCATION
                                        </div>
                                        <div className='summary-field-value'>
                                            {
                                                formRide.pickoffLocation
                                            }
                                        </div>
                                    </div>
                                    <div className='summary-fields'>
                                        <div className='summary-field-name'>
                                            RETURN PICKOFF LOCATION
                                        </div>
                                        <div className='summary-field-value'>
                                            {
                                                formRide.pickupLocation
                                            }
                                        </div>
                                    </div>
                                    <div className='summary-fields'>
                                        <div className='summary-field-name'>
                                            PICKUP DATE, TIME
                                        </div>
                                        <div className='summary-field-value'>
                                            {formRide.pickupDateReturn}, {formRide.pickupTimeReturn}
                                        </div>
                                    </div>
                                </div>
                                </>
                                :
                                <></>
                            }
                        </div>
                        <div className='col-md-4 ml-2 mr-2'>
                            <div className="clear-fix chbs-coupon-code-section">
                                <Accordion allowToggle>
                                    <AccordionItem className='no-board'>
                                    <h2>
                                        <AccordionButton>
                                        <AccordionIcon/>
                                        <Box flex='1' textAlign='left'>Do you have a discount code?</Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                    <div className="chbs-form-field">
                                        <label></label>
                                        <Textarea size='sm' value={code} onChange={(e) => setCode(e.target.value)} onKeyPress={handleKeyPress}/>
                                    </div>
                                    <div className='mb-2 mt-3 d-grid gap-2 d-md-block btn-coupon' style={{textAlign: '-webkit-center'}}>
                                        <button className='chbs-button chbs-button-style-2' style={{display: 'flex', justifyContent: 'center'}} onClick={handleApplyCode}>APPLY CODE</button>
                                    </div>
                                    </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                            <div className='summary-price'>
                                <div className='price-vehicle'>
                                    <span>Selected Vehicle</span>
                                    <span>${formVehicle.precioFinal}</span>
                                    <br></br>
                                    {
                                        totalExtra > 0 ?
                                        <>
                                        <span>Extra options</span>
                                        <span className='summary-price-extra'>${totalExtra}</span>
                                        </>:
                                        <></>
                                    }
                                </div>
                                {
                                    newTotal > 0 ?

                                    <div className='price-vehicle'>
                                        <span>Discount</span>
                                        <span> - ${discount}</span>
                                            <br></br>
                                    </div> :
                                    <></>
                                }
                                <div className='price-total'>
                                    {
                                        newTotal > 0 ?
                                        <>
                                            <span>Total</span>
                                            <span>${newTotal}</span>
                                        </>
                                            :
                                        <>
                                            <span>Total</span>
                                            <span>${total}</span>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='mb-2 mt-3 d-grid gap-2 d-md-block payment-button' style={{textAlignLast: 'center'}}>
                                {
                                    formContact.paymentMethod === 'Paypal'
                                        ? <>
                                                <div>
                                                { (isPending) && <div className="spinner" /> }
                                                    <PayPalButtons
                                                        style={{ layout: "vertical" }}
                                                        createOrder={createOrder}
                                                        onApprove={onApprove}
                                                    />
                                                </div>
                                        </>
                                        : formContact.paymentMethod === 'Pay using Credit/Debit Card' ?
                                        <button className='chbs-button chbs-button-style-2 selected-button' disabled={show} onClick={handleCheckout}>Complete Booking</button>
                                        : <button className='chbs-button chbs-button-style-2 selected-button' disabled={show} onClick={handleSubmit}>Complete Booking</button>
                                }
                                {
                                    Error ?
                                    <Alert status='error' style={{marginBottom:'10px'}}>
                                        <AlertIcon />
                                        <AlertTitle>Error with Paypal!</AlertTitle>
                                        <AlertDescription>We have a error with Paypal, please try again.</AlertDescription>
                                    </Alert>
                                    : <></>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
