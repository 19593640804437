import React from 'react';

import { SimpleGrid, Box, Image, Text } from "@chakra-ui/react";


function CardBooks() {

    const data = [{
        img: 'https://royalpickupsrc.s3.amazonaws.com/icons/Private_service.webp',
        title: "PRIVATE SERVICE",
        section: "Enjoy the comfortable ride that Royal Pickup offers ",
        subsection: "without sharing your vehicle ",
        description: "with strangers."
    },
    {
        img: 'https://royalpickupsrc.s3.amazonaws.com/icons/25_years.webp',
        title: "25 YEARS EXPERIENCE",
        section: "We have been providing services since 1996 as the ",
        subsection: "main operator of the Mexican Caribbean.",
        description: "Now, we are a click away from giving you this experience to you!"
    },
    {
        img: 'https://royalpickupsrc.s3.amazonaws.com/icons/flight_tracking.webp',
        title: "FLIGHT TRACKING",
        section: "Do not worry if your flight is delayed. All our services have ",
        subsection: "Delayed Flight Insurance ",
        description: "so you don’t have to rush to the transfer service, we will wait for your arrival."
    },
    {
        img: 'https://royalpickupsrc.s3.amazonaws.com/icons/No_booking_fee.webp',
        title: "NO BOOKING FEE",
        section: "As you are booking directly with the operator. ",
        subsection: "Forget about hidden fees ",
        description: "from third parties. Pay only for your service."
    },
    {
        img: 'https://royalpickupsrc.s3.amazonaws.com/icons/reliable_transfers.webp',
        title: "RELIABLE TRANSFERS",
        section: "",
        subsection: "Our vehicles are under constant revision ",
        description: "to improve your safety and offer you the Best Service."
    },
    {
        img:'https://royalpickupsrc.s3.amazonaws.com/payments/paydebitcard.webp',
        title: "PAYMENT METHODS",
        section: "Book your shuttle service via ",
        subsection: "PayPal, credit card or pay upon arrival.",
        description: ""
    },
    {
        img: 'https://royalpickupsrc.s3.amazonaws.com/icons/extras.webp',
        title: "EXTRAS",
        section: "",
        subsection: "Add complements ",
        description: "to make your ride extra special. You can order beer, water bottles, champagne and more!"
    },
    {
        img: 'https://royalpickupsrc.s3.amazonaws.com/icons/personal_service.webp',
        title: "PERSONAL SERVICE",
        section: "You can ",
        subsection: "choose from different shuttle vehicles ",
        description: "on our fleet. Like our Suburban’s, Van´s or our signature Royal Vehicle."
    },
    {
        img: 'https://royalpickupsrc.s3.amazonaws.com/icons/profesional_service.webp',
        title: "PROFESSIONAL SERVICE",
        section: "A fully qualified member of ",
        subsection: "Royal Pickup will be waiting for you with a signboard, ",
        description: "ready to answer any of your questions and make your ride a pleasant experience."
    }]

    return (
        <div className='container' style={{marginTop:'75px'}}>
            <SimpleGrid columns={{ base: 2, md: 2, lg: 3 }} spacingX='40px' spacingY='20px'>
                {
                data.map((item, idx) => {
                    return(
                        <Box p={4} display={{ md: 'flex' }} bg='#dddddd' rounded={'lg'} key={idx}>
                            <Box flexShrink={0} >
                                <Image
                                borderRadius='lg'
                                width={{ md: 40 }}
                                src={item.img}
                                alt={item.img}
                                />
                            </Box>
                            <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                                <Text
                                fontWeight='bold'
                                textTransform='uppercase'
                                fontSize='sm'
                                letterSpacing='wide'
                                color='#631042'
                                >
                                 {item.title}
                                </Text>
                                <p>{item.section}
                                    <strong>{item.subsection}</strong>
                                {item.description}</p>
                            </Box>
                            </Box>
                        )
                    })
                }
            </SimpleGrid>
        </div>
    );

}

export default CardBooks;