import { Maps } from '../../components/Maps';

const Cancun = () => {

    return (
        <>
            <div className='sections'>
                <div className='landing mb-4'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 order-sm-first order-last">
                                <Maps/>
                            </div>
                            <div className="col-lg-8 col-md-8 col-12 text-captions order-sm-first">
                                <h1 className='captions-royal'>Cancún</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sections' style={{paddingBottom:"50px"}}>
                <div className='row'>
                    <div className='col-md-6 col-lg-6 col-12'>

                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <div className='container'>
                            <h2 className="title-red" style={{paddingBottom:"25px", paddingTop:"25px"}}>CANCÚN PRIVATE TRANSFERS</h2>
                            <span>
                                Your next vacation to the Mexican Caribbean is closer than
                                ever, all you need is to choose the private transportation that
                                will take you from the Cancun International Airport to any
                                Hotel, Airbnb or destination you want.
                            </span>
                            <br/>
                            <br/>
                            <span>
                                Don’t think twice and book the best private transfer service with us!
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <div className='container'>
                            <h2 className="title-red" style={{paddingBottom:"25px", paddingTop:"25px"}}>CANCUN VEHICLES FOR PRIVATE TRANSFERS</h2>
                            <span>
                                To enjoy one of our royalty-worthy services, you just have to
                                select your date, time, place of origin and destination, choose
                                your favorite vehicle and add additional amenities to make
                                your travel even more special.
                            </span>
                            <br/>
                            <br/>
                            <span>
                                In <span style={{fontWeight: "500"}}>Royal PickUp </span> you can find the best option for your vacation:
                                <br/>
                                A Van with maximum capacity of <span style={{fontWeight: "500"}}>8 passenger</span>, a <span style={{fontWeight: "500"}}>Suburban</span> with a maximun capacity of <span style={{fontWeight: "500"}}>5 passengers</span> or our luxury <span style={{fontWeight: "500"}}>Navigator</span> with maximum capacity of <span style={{fontWeight: "500"}}>5 passengers.</span>
                            </span>
                            <br/>
                            <br/>
                            <span>
                                <span style={{fontWeight: "500"}}>* Estimated time where issues beyond our reach could interfere.</span>
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>

                    </div>


                    <div className='col-md-6 col-lg-6 col-12'>

                    </div>
                    <div className='col-md-6 col-lg-6 col-12'>
                        <div className='container'>
                            <h2 className="title-red" style={{paddingBottom:"25px", paddingTop:"25px"}}>THE BEST VACATIONS IN CANCUN</h2>
                            <span>
                                Cancun is approximately 20 minutes* from the Cancun
                                International Airport, located in a privileged area next to the
                                Caribbean Sea. Along the way, you will find unique landscapes
                                in the middle of the Mayan jungle, cenotes, hotels with
                                incredible views and the most beautiful beaches in the country.
                            </span>
                            <br/>
                            <br/>
                            <span>
                                From there you can visit the most popular sites of the <span style={{fontWeight: "500"}}>Yucatan
                                Peninsula</span>, such as its famous archeological sites, theme parks,
                                tours to nature reserves, renowned restaurants and many other
                                activities of paradise.
                            </span>
                            <br/>
                            <br/>
                            <span>
                                <span style={{fontWeight: "500"}}>* Estimated time where issues beyond our reach could interfere.</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <section className="background-banner" style={{ marginBottom:"50px"}}>
                <div className="container" style={{margin: '15px'}}>
                    <h2 style={{fontWeight: 'bold'}}>NEED A SAFE PRIVATE TRANSFER SERVICE? DO YOU WANT QUALITY IN VEHICLE AND SERVICE? YOU NOW WHAT TO DO!</h2>
                </div>
            </section>
        </>
    );

}

export default Cancun;
