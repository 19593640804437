const Faqs = () => {

    const redirectLink = (url) => {
        window.open(url, '_blank');
    }

    return(
        <>
            <h1 className="titleSection">Frequently Asked Questions (FAQ’S)</h1>
            <div className="container pb-4">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                I book my transfer in advance?
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes, you can make a reservation 24 hours prior the request. We recommend checking all information is correct so we can provide a better service for you and your companions.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How can I book?
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                We recommend using our <a href="#" onClick={() => redirectLink('https://www.royalpickup.com/ride-details/')} className="link-text">online booking system </a> or if you prefer,
                                 you can always contact us through our email <a href="mailto:sales@royalpickup.com?Subject=Booking" className="link-text"> sales@royalpickup.com </a>or calling
                                 <a href="tel:+529987348024" className="link-text">+52 (998) 734 8024 </a> for more information. Our reservation service is open 24 hours a day, 7 days a week.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                How can I pay?
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                We offer 2 different ways to pay; payment online through Paypal and credit cards or if you prefer you can also use our payment upon arrival with cash or debit/credit card.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                How do I know you have my reservation?
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                After making your reservation, you will receive your confirmation by email. The voucher contains the information that you attached at the time of booking. If you do not receive the coupon, <a href="#" onClick={() => redirectLink('https://www.royalpickup.com/contact-us/')} className="link-text">contact us. </a>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Can I change or cancel my reservation?
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                You can change or cancel your reservation at any time before the transfer. However, cancellation fees may apply. Please read our terms and conditions for cancellation.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                What is your cancellation policy?
                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ul className="list-bullet">
                                    <li>Cancellations made 24 hours or more prior to your service, have a 10% cancellation charge.</li>
                                    <li>Cancellations made 8 to 24 hours before your service, have a 50% cancellation charge.</li>
                                    <li>Cancellations made 8 hours or less before your service will not have a refund.</li>
                                    <li>No refund will be issued on no show for arrivals or departures.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                Do I need to confirm my departure 24 hours before the transfer?
                            </button>
                        </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                No, Royal PickUp staff it’s always monitoring your flight and checking all information is correct prior your arrival, if you feel unsure or have any doubts on your reservation please
                                contact our email <a href="mailto:sales@royalpickup.com?Subject=Booking" className="link-text"> sales@royalpickup.com </a>or phone number
                                 <a href="tel:+529987348024" className="link-text">+52 (998) 734 8024 </a>
                                and we will gladly assist you.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Where does the driver leaves me upon my departure flight?
                            </button>
                        </h2>
                        <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                The driver will leave you at the correct terminal of the airline you are flying with or the one you are instructing.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                Where do I find the driver?
                            </button>
                        </h2>
                        <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Our staff stays outside the airport holding a sign with your name on it.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                What if we cannot find our host or driver?
                            </button>
                        </h2>
                        <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                You can contact us at the following telephone number: <a href="tel:+529987348024" className="link-text"> +52 (998) 734 8024 </a> for immediate assistance.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEleven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                How can I contact you from the airport?
                            </button>
                        </h2>
                        <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                You can verify your reservation voucher to obtain the local phone number and toll-free number of our offices and staff in charge.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwelve">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                What happens if my flight is delayed?
                            </button>
                        </h2>
                        <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Don’t worry all our transfers have flight delayed insurance. That means we will wait for you no matter how long your flight delays.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                What happens if my flight arrives early?
                            </button>
                        </h2>
                        <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                No problem, our staff is always monitoring your flight with our flight tracking service to always keep an eye on your flight even if it delays or arrives early.
                            <br/>
                                If for some reason you don’t see our staff, please contact us. We strongly urge you to contact our telephone numbers.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFourteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                What are the restrictions on luggage?
                            </button>
                        </h2>
                        <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                We consider 1 luggage per person, if you have more luggage than usual or travel with luggage that requires extra space, please tell us in advance so we can arrange your transfer.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFifteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                How long does my trip last?
                            </button>
                        </h2>
                        <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingFifteen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <ul className="list-bullet">
                                    <li>Hotel Zone in Cancun for 15 to 30 minutes.</li>
                                    <li>Cancun downtown / Playa Mujeres / Puerto Juarez – 30 minutes to 40 minutes.</li>
                                    <li>Puerto Morelos – 40 to 50 minutes.</li>
                                    <li>Playa Del Carmen – 50 to 60 minutes.</li>
                                    <li>Puerto Aventuras – 1 to 1.2 hours.</li>
                                    <li>Akumal / Tulum / Zona Hotelera Tulum (Boca Paila) – 1.5 to 2.5 hours.</li>
                                </ul>
                                Times are just estimates, depends on your destination and traffic.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSixteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                I left a belongings in the van. What I can do?
                            </button>
                        </h2>
                        <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingSixteen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Please call or email us immediately. Remember that all our drivers provide services for many passengers throughout the day and your belongings can be lost or picked up by a different passenger if not reported immediately. After every service our drivers check the vehicle to make sure you don’t forget anything, however, if our driver finds it, we will immediately deliver it to you, in case we find your belongings after leaving you at your destination we will help you find the best way to return your items, you should consider that not all passengers are going to the same destination.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeventeen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                Can I get a transfer from/to any airport?
                            </button>
                        </h2>
                        <div id="collapseSeventeen" className="accordion-collapse collapse" aria-labelledby="headingSeventeen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                For the moment, our transfers are only available at Cancun International Airport.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEighteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                Do you offer private transfers?
                            </button>
                        </h2>
                        <div id="collapseEighteen" className="accordion-collapse collapse" aria-labelledby="headingEighteen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes. All our transfers are private, you can even add special requests to make your service extra special.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNineteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                Do the vehicles have air conditioning?
                            </button>
                        </h2>
                        <div id="collapseNineteen" className="accordion-collapse collapse" aria-labelledby="headingNineteen" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes. All our vehicles have air conditioning.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwenty">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                How many seats do your vehicles have?
                            </button>
                        </h2>
                        <div id="collapseTwenty" className="accordion-collapse collapse" aria-labelledby="headingTwenty" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Most of our vehicles are SUV’s that can accommodate up to five passengers. Vans have accommodation for up to eight passengers.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                Do you provide car seats for children under the age of three?
                            </button>
                        </h2>
                        <div id="collapseTwentyTwo" className="accordion-collapse collapse" aria-labelledby="headingTwentyTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes, we have, all our transportation vehicles have child safety seats whenever requested at the time of making their reservation.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentythree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentythree" aria-expanded="false" aria-controls="collapseTwentythree">
                                Should I tip the driver? How much should I give?
                            </button>
                        </h2>
                        <div id="collapseTwentythree" className="accordion-collapse collapse" aria-labelledby="headingTwentythree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                It´s not a mandatory thing to do. If you are satisfied with the driver’s service you can tip them, however, depends in each client. Drivers are heavily dependent on tips, but they know they must earn them giving you the best service possible.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                Is smoking allowed in your vehicles?
                            </button>
                        </h2>
                        <div id="collapseTwentyFour" className="accordion-collapse collapse" aria-labelledby="headingTwentyFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                No, we strictly operate a no smoking policy within our transportation vehicles.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                Can you take us to a supermarket or restaurant before going to our hotel?
                            </button>
                        </h2>
                        <div id="collapseTwentyFive" className="accordion-collapse collapse" aria-labelledby="headingTwentyFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                            Yes we can! We are here to assist you. Simply add those requests on the comment section in our online booking system or send us an <a href="mailto:sales@royalpickup.com?Subject=Booking" className="link-text"> email </a> or <a href="tel:+529987348024" className="link-text"> contact us </a> requesting the kind of service you would need. It is necessary to anticipate the stop to be able to consider the time since we work according to a previous itinerary. We will take you to the store that is on the way to your hotel.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentySix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
                                Do you offer a hotel-hotel shuttle?
                            </button>
                        </h2>
                        <div id="collapseTwentySix" className="accordion-collapse collapse" aria-labelledby="headingTwentySix" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes. We offer transfer services anywhere you want within Quintana Roo and Merida. Rates depend on distance.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Faqs;
