import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './rootSlice';
import carsReducer from './cartSlice';
import extrasReducer from './extraSlice';

const reducer = {
    root: rootReducer,
    car: carsReducer,
    extra: extrasReducer
}

export const store = configureStore({
    reducer: reducer
});
